import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Dropdown, Spinner, Overlay, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import zpiategorzeduLogo from './img/zpiategorzedu-logo.jpg';
import { format } from 'date-fns';

const Calendar = ({ POLAND_CITIES, t, arrowLeftIcon, arrowRightIcon, sunIcon, infoIcon, currentLocale, starIcon, chevronsRightIcon }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentCountry, setCurrentCountry] = useState(() => {
    const countryParam = searchParams.get('country');
    if (countryParam) return countryParam;
    
    // Sprawdź język przeglądarki
    const browserLanguage = navigator.language.toLowerCase();
    if (browserLanguage.startsWith('pl')) {
      setSearchParams({ country: 'Polska' });
      return 'Polska';
    }
    return null;
  });
  const [currentCity, setCurrentCity] = useState(searchParams.get('city') || null);
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [importInfo, setImportInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAllCities, setShowAllCities] = useState(false);
  const navigate = useNavigate();
  const [tooltipState, setTooltipState] = useState({
    visible: false,
    active: false,
    event: null,
    target: null,
    dayIndex: null
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const tooltipRef = useRef(null);

  // Dodaj ref do timeoutów
  const tooltipTimeoutRef = useRef(null);
  
  // Dodaj nowy stan na początku komponentu
  const [isTooltipPosterLoading, setIsTooltipPosterLoading] = useState(true);
  
  // Dodaj nowy stan dla overlaya
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  
  // Dodaj efekt do resetowania stanu loadera przy zmianie eventu
  useEffect(() => {
    if (tooltipState.event) {
      // Resetuj stan ładowania za każdym razem gdy zmienia się event
      setIsTooltipPosterLoading(true);
      
      // Stwórz nowy Image object do preloadowania
      const img = new Image();
      img.src = tooltipState.event.show.poster && tooltipState.event.show.poster.includes('http')
        ? tooltipState.event.show.poster
        : `${process.env.REACT_APP_API_BASE_URL}/img${tooltipState.event.show.poster || ''}`;
        
      img.onload = () => setIsTooltipPosterLoading(false);
      img.onerror = () => setIsTooltipPosterLoading(false);
    }
  }, [tooltipState.event]);
  
  // Funkcje do obsługi tooltipa z opóźnieniem
  const handleShowTooltip = (event, target, dayIndex) => {
    if (isMobile) {
      // Jeśli klikamy w ten sam element
      if (tooltipState.target === target) {
        return;
      }
      
      // Najpierw ustawiamy nowe dane bez pokazywania
      setTooltipState(prev => ({
        ...prev,
        event,
        target,
        dayIndex,
        visible: true
      }));

      // Aktywuj overlay
      setIsOverlayActive(true);

      // Następnie włączamy animację w następnym cyklu
      requestAnimationFrame(() => {
        setTooltipState(prev => ({
          ...prev,
          active: true
        }));
      });
    } else {
      // Desktop logic
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      tooltipTimeoutRef.current = setTimeout(() => {
        setTooltipState({
          visible: true,
          active: true,
          event,
          target,
          dayIndex
        });
      }, 50);
    }
  };

  const handleHideTooltip = () => {
    if (!isMobile) {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      tooltipTimeoutRef.current = setTimeout(() => {
        setTooltipState(prev => ({
          ...prev,
          active: false,
          visible: false
        }));
      }, 250);
    }
  };

  // Cleanup przy odmontowaniu komponentu
  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchImportInfo = async () => {
      try {
        console.log('Wysyłam zapytanie o aktywny import');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/current`);
        console.log('Otrzymano odpowiedź:', response.data);
        setImportInfo(response.data);
      } catch (error) {
        console.error('Error fetching import info:', error);
      }
    };
    fetchImportInfo();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      try {
        console.log('Wysyłam zapytanie o wydarzenia:', {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1,
          country: currentCountry,
          city: currentCity
        });
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/events`, {
          params: {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            country: currentCountry,
            city: currentCity
          }
        });
        console.log('Otrzymano odpowiedź z wydarzeniami:', response.data);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEvents();
  }, [currentDate, currentCountry, currentCity]);

    // Funkcje obsługujące filtrowanie
    const resetCountryFilter = () => {
        setCurrentCountry(null);
        setCurrentCity(null);
        setSearchParams({});
      };
    
      const resetCityFilter = () => {
        setCurrentCity(null);
        setSearchParams({ country: currentCountry });
      };

  const handleCountryChange = (country) => {
    setCurrentCountry(country);
    setCurrentCity(null);
    setSearchParams({ country });
  };

  const handleCityChange = (city) => {
    setCurrentCity(city);
    setSearchParams({ country: currentCountry, city });
  };


    // Funkcja do filtrowania miast na podstawie spektakli dostępnych w bazie
    const getCitiesForCountry = (country) => {
        if (country === 'Polska') {
          const availableCities = POLAND_CITIES.filter(city =>
            events.some(event => 
              event.altLocation === city || (!event.altLocation && event.show.location === city)
            )
          );
          return availableCities;
        }
        return Array.from(new Set(events.flatMap(event => {
          const cities = [];
          if (event.show.country === country) {
            if (event.altLocation) {
              cities.push(event.altLocation);
            } else {
              cities.push(event.show.location);
            }
          }
          return cities;
        })));
    };

  const getAllCitiesForCountry = (country) => {
    if (country === 'Polska') {
      return POLAND_CITIES;
    }
    return Array.from(new Set(events.map(event => 
      event.show.country === country ? event.show.location : null
    ))).filter(city => city !== null);
  };

  const renderCityItems = () => {
    const filteredCities = getCitiesForCountry(currentCountry);
    const allCities = getAllCitiesForCountry(currentCountry);
    
    const citiesToShow = showAllCities ? allCities : filteredCities;

    return (
      <>
        <Dropdown.Item className="reset-city-filter" onClick={resetCityFilter}>{t('best.reset')}</Dropdown.Item>
        {citiesToShow.map(city => (
          <Dropdown.Item key={city} onClick={() => handleCityChange(city)}>
            {city}
          </Dropdown.Item>
        ))}
        {!showAllCities && allCities.length > filteredCities.length && (
          <Dropdown.Item 
            onClick={() => setShowAllCities(true)}
            className="show-more-item"
          >
            {t('calendar.show-more')}
          </Dropdown.Item>
        )}
      </>
    );
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];

    // Konwertuj dzień tygodnia na format poniedziałek = 0, niedziela = 6
    const getAdjustedDay = (date) => {
      let day = date.getDay();
      return day === 0 ? 6 : day - 1;
    };

    // Dodaj puste komórki na początku miesiąca
    const firstDayOfWeek = getAdjustedDay(firstDay);
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty" />);
    }

    // Dodaj dni miesiąca
    for (let day = 1; day <= lastDay.getDate(); day++) {
      const currentDay = new Date(year, month, day);
      const dayOfWeek = getAdjustedDay(currentDay);
      const dayEvents = events
        .filter(event => 
          event.dates.some(date => 
            new Date(date).toDateString() === currentDay.toDateString()
          )
        )
        // Sortowanie najpierw po miastach, potem po tytułach
        .sort((a, b) => {
          // Pobierz lokalizacje (z uwzględnieniem altLocation)
          const locationA = a.altLocation || a.show.location;
          const locationB = b.altLocation || b.show.location;
          
          // Najpierw sortuj po miastach
          const cityCompare = locationA.localeCompare(locationB, undefined, {sensitivity: 'base'});
          
          // Jeśli miasta są różne, zwróć wynik porównania miast
          if (cityCompare !== 0) {
            return cityCompare;
          }
          
          // Jeśli miasta są takie same, sortuj po tytułach
          return a.show.title.localeCompare(b.show.title, undefined, {sensitivity: 'base'});
        });

      const maxEventsToShow = 5;
      const hasMoreEvents = dayEvents.length > maxEventsToShow;

      days.push(
        <div 
          key={day} 
          className={`calendar-day ${dayEvents.length > 0 ? 'has-events' : ''}`}
          data-day-index={dayOfWeek}
          onClick={(e) => {
            if (e.target.classList.contains('calendar-day') || e.target.classList.contains('day-number')) {
              const params = new URLSearchParams();
              params.set('date', currentDay.toISOString());
              if (currentCountry) params.set('country', currentCountry);
              if (currentCity) params.set('city', currentCity);
              navigate(`/calendar/day?${params.toString()}`);
            }
          }}
        >
          <div className="day-number">{day}</div>
          <div className="day-events">
            {dayEvents.slice(0, maxEventsToShow).map((event, index) => {
              // Pobierz wszystkie godziny spektakli w danym dniu
              const todayShowTimes = event.dates.filter(date => 
                new Date(date).toDateString() === currentDay.toDateString()
              );
              
              // Sprawdź czy jest więcej niż jeden spektakl w tym dniu
              const hasMultipleShows = todayShowTimes.length > 1;

              return (
                <Link 
                  key={`${event.show._id}-${index}`}
                  to={`/shows/${event.show.slug}`}
                  className={`overlay-shadow event-link ${event.altLocation ? event.altLocation.toLowerCase().replace(/\s+/g, '-') : event.show.location.toLowerCase().replace(/\s+/g, '-')} ${hasMultipleShows ? 'has-multiple' : ''}`}
                  onMouseEnter={(e) => !isMobile && handleShowTooltip(event, e.target, dayOfWeek)}
                  onMouseLeave={() => !isMobile && handleHideTooltip()}
                  onClick={(e) => {
                    if (isMobile) {
                      e.preventDefault();
                      handleShowTooltip(event, e.target, dayOfWeek);
                    }
                  }}
                >
                  {event.show.title}
                  {hasMultipleShows && (
                    <span className="multiple-shows-icon">{isMobile ? '•' : '2'}</span>
                  )}
                </Link>
              );
            })}
            {hasMoreEvents && (
              <Link
                to={`/calendar/day?date=${currentDay.toISOString()}${currentCountry ? `&country=${currentCountry}` : ''}${currentCity ? `&city=${currentCity}` : ''}`}
                className="more-events-link"
              >
                {t('calendar.more')}...
              </Link>
            )}
          </div>
        </div>
      );
    }

    return days;
  };

  // Dodaj funkcję do tłumaczenia dni tygodnia
  const getLocalizedWeekDays = () => {
    const weekDays = [];
    for (let i = 1; i <= 7; i++) {
      const date = new Date(2024, 0, i);
      weekDays.push(
        format(date, 'EEE', { 
          locale: currentLocale  
        })
      );
    }
    return weekDays;
  };

  // Zmodyfikuj wyświetlanie miesiąca i roku
  const getLocalizedMonthYear = (date) => {
    return format(date, 'LLLL yyyy', { 
      locale: currentLocale  
    });
  };

  // Dodaj efekt do wykrywania rozmiaru ekranu
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Zmodyfikuj efekt obsługujący kliknięcia poza tooltipem
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobile && tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipState(prev => ({
          ...prev,
          visible: false,
          active: false,
          target: null
        }));
        setIsOverlayActive(false);
      }
    };

    if (isMobile && tooltipState.visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile, tooltipState.visible]);

  // Zmodyfikuj funkcję getTooltipPlacement
  const getTooltipPlacement = (dayIndex) => {
    if (isMobile) {
      return "top";
    }
    return dayIndex >= 4 ? "left" : "right";
  };

  return (
    <>
      {/* Dodaj overlay przed głównym containerem */}
      {isMobile && isOverlayActive && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 1040
          }}
        />
      )}
      
      <Container fluid className="px-3 main-container calendar-main">
        <Row className="g-0 best-played mb-5 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          
          <div className="header">
            <h1 className="page-title">{t('calendar.title')}</h1>
            {importInfo && (
              <p className="update-info">
                {t('calendar.last-update')}: {new Date(importInfo.updatedAt).toLocaleDateString()}
              </p>
            )}
            </div>
            

            <div className="mb-5 intro">
            <p>{t('calendar.p-author')} <strong>Kinga Zaborowska</strong> (<strong><a rel="noreferrer" href="https://www.instagram.com/z_piatego_rzedu/" target="_blank" alt="Kinga Zaborowska">@z_piątego_rzędu</a></strong>).
            {t('calendar.p-support')} <a href="https://buycoffee.to/zpiategorzedu" rel="noreferrer" target="_blank" alt="Wesprzyj Kingę">{t('calendar.p-support2')}</a>.</p>
            <img src={zpiategorzeduLogo} alt="Z piątego rzędu" className="zpiategorzedu-logo" />

            </div>
            



<div className="calendar-container-header">
          <div className="calendar-navigation">
  
              <img src={arrowLeftIcon} title="Previous" alt="Previous" onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))} className="nav-arrow" />
           
            <h2>
              {getLocalizedMonthYear(currentDate)}
            </h2>

              <img src={arrowRightIcon} title="Next" alt="Next" onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))} className="nav-arrow" />

          </div>

          <div className="filter-container">
            <Dropdown drop="auto">
              <Dropdown.Toggle variant="primary" id="dropdown-country">
                {currentCountry || t('calendar.select-country')}
              </Dropdown.Toggle>
              <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
              <Dropdown.Item className="reset-city-filter" onClick={resetCountryFilter}>{t('best.reset')}</Dropdown.Item>
              {Array.from(new Set(events.map(event => event.show.country)))
                  .filter(country => country && country.trim() !== '')
                  .sort((a, b) =>
                    events.filter(event => event.show.country === b).length - 
                    events.filter(event => event.show.country === a).length
                  ).map(country => (
                  <Dropdown.Item key={country} onClick={() => handleCountryChange(country)}>
                    {country}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {currentCountry && (
              <Dropdown drop="auto">
                <Dropdown.Toggle variant="light" id="dropdown-city">
                  {currentCity || t('calendar.select-city')}
                </Dropdown.Toggle>
                <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                  {renderCityItems()}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          </div>

          {isLoading ? (
            <div className="text-center my-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
            <div className="calendar-grid">
              {getLocalizedWeekDays().map(day => (
                <div key={day} className="calendar-header">{day}</div>
              ))}
              {renderCalendar()}
              
            </div>
            <div className="all-shows-info justify-content-center mt-5">
            <img src={infoIcon} alt="Info" className="info-icon" />
                {t('calendar.p-liability')}
                </div>
            </>
          )}
        
          </Col>
        </Row>
      </Container>

      <Overlay
        show={tooltipState.visible}
        target={tooltipState.target}
        placement={tooltipState.dayIndex !== null ? getTooltipPlacement(tooltipState.dayIndex) : "right"}
        container={document.body}
        transition={false}
        offset={[0, 8]}
      >
        {(props) => (
          <Tooltip 
            id="show-tooltip" 
            {...props} 
            className={`show-tooltip ${tooltipState.active ? 'show' : ''}`}
          >
            {tooltipState.event && (
              <div 
                ref={tooltipRef}
                className="show-tooltip-content"
                onMouseEnter={() => {
                  if (!isMobile && tooltipTimeoutRef.current) {
                    clearTimeout(tooltipTimeoutRef.current);
                  }
                  setTooltipState(prev => ({ ...prev, visible: true, active: true }));
                }}
                onMouseLeave={() => !isMobile && handleHideTooltip()}
                onClick={() => navigate(`/shows/${tooltipState.event.show.slug}`)}
                style={{ cursor: 'pointer' }}
              >
                <div className="tooltip-poster-container">
                  <div className="tooltip-poster">
                    <div className={`skeleton-loader ${isTooltipPosterLoading ? 'visible' : ''}`} />
                    {tooltipState.event && (
                      <img 
                        key={tooltipState.event.show._id}
                        src={tooltipState.event.show.poster && tooltipState.event.show.poster.includes('http')
                          ? tooltipState.event.show.poster
                          : `${process.env.REACT_APP_API_BASE_URL}/img${tooltipState.event.show.poster || ''}`}
                        alt={tooltipState.event.show.title}
                        className={`poster-img ${isTooltipPosterLoading ? 'hidden' : 'visible'}`}
                      />
                    )}
                  </div>
                </div>
                <div className="tooltip-info">
                  <div className="tooltip-location">
                    {tooltipState.event.altLocation || tooltipState.event.show.location}
                  </div>
                  <h4>
                    {tooltipState.event.show.title}
                  </h4>
                  <div className="tooltip-theater">{tooltipState.event.show.theater}</div>
                  {tooltipState.event.show.avgRating > 0 && (
                    <div className="tooltip-rating">
                      <img src={starIcon} alt="Rating" className="rating-star" />
                      <span>{tooltipState.event.show.avgRating.toFixed(1)}</span>
                    </div>
                  )}
                                      {tooltipState.event.dates.filter(date => {
                      const dayNumber = tooltipState.target.closest('.calendar-day').querySelector('.day-number').textContent;
                      const currentDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), parseInt(dayNumber));
                      return new Date(date).toDateString() === currentDayDate.toDateString();
                    }).length > 1 && (
                      <div className="multiple-shows-tag">
                      2 {t('calendar.shows-today')}
                    </div>
                    )}
                </div>
                <div className="tooltip-chevrons">
                  <img src={chevronsRightIcon} alt="Chevrons right" className="chevron-right" />
                </div>
              </div>
            )}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default Calendar; 