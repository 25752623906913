import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logoGrey from './img/logo-grey.svg';  // Zaktualizuj ścieżkę do logo
import socialInstaIcon from './img/icons/social-instagram.svg';  // Zaktualizuj ścieżkę do ikony Instagram
import { Link } from 'react-router-dom';
import axios from 'axios';



const Footer = ( {
  t, changeLanguage
} ) => {
  
  const handleForumLogin = async () => {
    try {
      const csrfResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/csrf-token`);
      const csrfToken = csrfResponse.data.csrfToken;

        console.log('Rozpoczęcie procesu logowania do forum');
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/auth/discourse-sso-init`, 
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'X-CSRF-Token': csrfToken
                }
            }
        );

        if (response.data.redirectUrl) {
            console.log('Przekierowanie do:', response.data.redirectUrl);
            window.location.href = response.data.redirectUrl;
        }
    } catch (error) {
        console.error('Błąd podczas logowania do forum:', error);
        // Możesz tutaj dodać obsługę błędów, np. wyświetlenie komunikatu
    }
  };

  return (
    <Container fluid className="px-3 main-container footer-container pb-5">
      {/* Nowa sekcja w stopce */}
      <Row className="g-0 text-center my-5 footer-top">
        <Col xs={12}>
          <img src={logoGrey} alt="Showify Logo" className="footer-logo" />
        </Col>
        <Col xs={12}>
          <div className="footer-links">
          <Link onClick={handleForumLogin}>T</Link>
            <a href="/about" className="footer-link">{t('footer.about')}</a>
            <a href="/about" className="footer-link">{t('footer.contact')}</a>
            <div className="language-choice">
                  <Link onClick={() => changeLanguage('en')}>🇬🇧</Link>
                  <span>&nbsp;·&nbsp;</span>
                  <Link onClick={() => changeLanguage('pl')}>🇵🇱</Link>
                  </div>
          </div>

        </Col>
        <Col xs={12}>
          <div className="footer-social">
            <a href="https://www.instagram.com/showify_polska/" target="_blank" rel="noopener noreferrer">
              <img src={socialInstaIcon} alt="Instagram" className="social-icon" />
            </a>
          </div>

        </Col>
      </Row>

      {/* Footer */}
      <Row className="g-0 text-center my-0 footer ">
        <Col xs={12}>
          <p>&copy; {t('footer.rights')}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;