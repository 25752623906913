import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';


import { useTranslation } from 'react-i18next';
import i18next from './i18next';
import { pl, enUS } from 'date-fns/locale';

import HomePage from './Homepage';
import BestPlayedPage from './BestPlayedPage';
import BestAllTime from './BestAllTime';
import GoogleSignUp from './GoogleSignUp'; 
import Diary from './Diary';
import AddToDiary from './AddToDiary'; 
import ShowPage from './ShowPage';  
import UserPage from './UserPage';  
import MyAccount from './MyAccount';  
import StatsRank from './StatsRank';  
import Navigation from './Navigation'; 
import Footer from './Footer'; 
import BottomMenu from './BottomMenu'; // Importuj nowy komponent
import CookieConsent from './CookieConsent';
import About from './About';
import PersonPage from './PersonPage';
import ActivityFeed from './ActivityFeed';
import Calendar from './Calendar';
import CalendarDayView from './CalendarDayView';




import { jwtDecode } from 'jwt-decode';


// Import ikon
import searchIcon from './img/icons/search.svg';
import starIcon from './img/icons/star.svg';
import calendarIcon from './img/icons/calendar.svg';
import rankIcon from './img/icons/rank.svg';
import userIcon from './img/icons/user.svg';
import heartIcon from './img/icons/heart.svg';
import messageIcon from './img/icons/message.svg';
import fileIcon from './img/icons/file.svg';
import musicIcon from './img/icons/music.svg';
import clockIcon from './img/icons/clock.svg';
import plusIcon from './img/icons/plus.svg';
import successIcon from './img/icons/success.svg';
import infoIcon from './img/icons/info.svg';
import homeIcon from './img/icons/home.svg';
import compassIcon from './img/icons/compass.svg';
import helpIcon from './img/icons/help.svg';
import arrowLeftIcon from './img/icons/arrow-left.svg';
import arrowRightIcon from './img/icons/arrow-right.svg';
import chevronDownIcon from './img/icons/chevron-down.svg';
import chevronUpIcon from './img/icons/chevron-up.svg';
import dragIcon from './img/icon-menu.svg';
import eyeIcon from './img/icons/eye.svg';
import globeIcon from './img/icons/globe.svg';
import shareIcon from './img/icons/share.svg';
import shareLinkIcon from './img/icons/share-2.svg';
import downloadIcon from './img/icons/download.svg';
import settingsIcon from './img/icons/settings.svg';
import noteIcon from './img/icons/note.svg';
import shieldIcon from './img/icons/shield.svg';
import keyIcon from './img/icons/key.svg';
import arrowLeftRegIcon from './img/icons/arrow-left-reg.svg';
import chevronsRightIcon from './img/icons/chevrons-right.svg';
import sunIcon from './img/icons/sun.svg';
import checkCircleIcon from './img/icons/check-circle.svg';
import activityIcon from './img/icons/activity.svg';







import defaultPoster from './img/defaults/default-poster.png';
import forumLogo from './img/forum-logo.png';


import bigRankIcon from './img/icons/big-rank.svg';
import bigRankAllIcon from './img/icons/big-rank-all.svg'; 
import bigDiaryIcon from './img/icons/big-diary.svg';
import bigFeaturedIcon from './img/icons/big-featured.svg';
import bigHomeIcon from './img/icons/big-home.svg';
import bigLogoutIcon from './img/icons/big-logout.svg';
import colorCircleCalendarIcon from './img/icons/color-circle-calendar.svg';
import colorCircleStarIcon from './img/icons/color-circle-star.svg';


// Globalna konfiguracja axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://showify-app-ed20531d82b3.herokuapp.com/api';

const App = () => {
  // Stan aplikacji
  const [user, setUser] = useState(null);
  const [shows, setShows] = useState([]);
  const [userDiary, setUserDiary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('Warszawa');
  

// Dodaj na początku pliku App.js
const checkUserSession = () => {
  const token = localStorage.getItem('token');
  const storedUser = localStorage.getItem('user');
  
  if (!token || !storedUser) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return null;
  }
  
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return null;
    }
    
    return JSON.parse(storedUser);
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return null;
  }
};

  // Pobieranie danych użytkownika z localStorage
  useEffect(() => {
    const validUser = checkUserSession();
    setUser(validUser);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        // Wylogowanie użytkownika lub inne działania
      }
    }
  }, []);



  // Pobieranie listy spektakli oraz dziennika użytkownika
  useEffect(() => {
    async function fetchShows() {
      try {
        setIsLoading(true);
  
        // Pobieranie danych spektakli (niezależnie od logowania)
        const showsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows`);
        setShows(showsResponse.data);
  
        // Pobieranie dziennika użytkownika tylko, jeśli token istnieje
        const token = localStorage.getItem('token');
        if (token) {
          const diaryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserDiary(diaryResponse.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleLogout(); // Wylogowanie w przypadku błędu 401
        }
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  
    fetchShows();
  }, []);
  
  

  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  const localeMap = {
    en: enUS,
    pl: pl,
  };

    // Wybieramy locale na podstawie aktualnego języka z i18n
    const currentLocale = localeMap[i18next.language] || pl;
  
// Pobranie oceny użytkownika dla konkretnego spektaklu
const getUserRatingForShow = (showId) => {
  // Filtrowanie wpisów dla konkretnego showId
  const entries = userDiary.filter(entry => entry.show._id === showId);
  
  if (entries.length === 0) {
    return null;
  }

  // Filtrowanie ocen, aby uwzględnić tylko te, które nie są null
  const ratings = entries
    .map(entry => entry.userRating)
    .filter(rating => rating !== null);

  if (ratings.length === 0) {
    return null;
  }

  // Sumowanie ocen i obliczanie średniej
  const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
  const averageRating = totalRating / ratings.length;

  return averageRating.toFixed(1); // Zaokrąglenie do jednego miejsca po przecinku
};


  // Funkcja zwracająca opis oceny na podstawie wartości liczbowej
  const getRatingDescription = (rating) => {
    if (rating <= 1.5) return t('ratings.description.disaster');
    if (rating <= 2.5) return t('ratings.description.terrible');
    if (rating <= 3.5) return t('ratings.description.bad');
    if (rating <= 4.5) return t('ratings.description.below-average');
    if (rating <= 5.5) return t('ratings.description.average');
    if (rating <= 6.5) return t('ratings.description.above-average');
    if (rating <= 7.5) return t('ratings.description.good');
    if (rating <= 8.5) return t('ratings.description.very-good');
    if (rating <= 9.5) return t('ratings.description.excellent');
    if (rating > 9.5) return t('ratings.description.masterpiece');
    return t('ratings.description.none');
  };

  // Renderowanie gwiazdek oceny
  const renderStarRating = (rating) => {
    const totalStars = 10;
    const yellowStars = Math.round(rating);
    return (
      <div className="star-rating">
        {[...Array(totalStars)].map((_, i) => (
          <img
            key={i}
            src={starIcon}
            alt="Star"
            className={`star-icon ${i < yellowStars ? 'yellow-star' : 'gray-star'}`}
          />
        ))}
      </div>
    );
  };

  const POLAND_CITIES = [
    'Warszawa',
    'Gdynia',      // Teatr Muzyczny w Gdyni
    'Kraków',      // Opera Krakowska, Teatr im. Juliusza Słowackiego
    'Poznań',      // Teatr Muzyczny w Poznaniu
    'Łódź',        // Teatr Muzyczny w Łodzi
    'Chorzów',
    'Wrocław',     // Opera Wrocławska, Capitol Musical Theatre
    'Toruń',       // Teatr im. Wilama Horzycy
    'Bydgoszcz',   // Opera Nova
    'Gliwice',     // Teatr Muzyczny w Gliwicach
    'Gdańsk',      // Opera Bałtycka, Teatr Muzyczny w Gdyni
    'Lublin',      // Teatr Muzyczny w Lublinie
    'Szczecin',    // Opera na Zamku w Szczecinie
    'Białystok',   // Opera i Filharmonia Podlaska
    'Katowice',    // Teatr Śląski, NOSPR
    'Koszalin',
    'Słupsk'
  ];

  const FEATURED_SHOWS = [
    'tik-tik-bum',
    'six',
    'chlopcy-z-placu-broni',
    'avenue-q-1',
    'jesus-christ-superstar-1',
    'wicked',
    'six-1',
    'rent',
    'heathers-8',
    '1989'
  ];
  

  // Zmiana lokalizacji spektakli
  const handleChangeLocation = (location) => {
    setCurrentLocation(location);
  };

  // Filtracja i sortowanie spektakli według lokalizacji
  const showsByLocation = shows.filter(show => show.location === currentLocation);
  const showsByLocationSorted = showsByLocation
  .filter(show => show.globalRank !== null && show.globalRank > 0) // Dodanie filtra globalRank
  .sort((a, b) => a.globalRank - b.globalRank);

    // Sortowanie spektakli według avgRating
    const sortedShowsByLocation = showsByLocation
    .filter(show => show.globalRank !== null && show.globalRank > 0) // Dodanie filtra globalRank
    .sort((a, b) => b.avgRating - a.avgRating); // Sortowanie według średniej oceny malejąco
  
  // Dynamiczne określanie pozycji w rankingu
  const showsWithDynamicRank = sortedShowsByLocation.map((show, index) => ({
    ...show,
    dynamicRank: index + 1,
  }));

  // Obsługa elementów interfejsu użytkownika
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navSearchRef = useRef(null);
  const searchRef = useRef(null);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.icon')) {
      setShowMenu(false);
    }
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
    if (navSearchRef.current && !navSearchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
    setShowMenu(false);
  };

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
    setShowSearch(false);
  };

  const handleLogout = async () => {
    try {
      console.log('Starting logout process...');
      
      // Najpierw wyczyść lokalne dane
      setUser(null);
      localStorage.removeItem('user');
      const token = localStorage.getItem('token');
      localStorage.removeItem('token');
      console.log('Cleared local storage and user state');
      
      // Wyloguj z Google
      if (window.google?.accounts.id) {
        window.google.accounts.id.disableAutoSelect();
        console.log('Disabled Google auto-select');
      }

      // Wyloguj z forum przez nasz backend
      try {
        console.log('Attempting forum logout through backend...');
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/discourse-logout`,
          {},
          { 
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('Forum logout successful');
      } catch (forumError) {
        console.error('Forum logout error:', forumError);
      }

      // Wyczyść ciasteczka Discourse
      const discourseCookies = ['_t', 'destination_url', 'discourse_sso', 'discourse_sso_payload', 'discourse_sso_sig', 'auth_token'];
      discourseCookies.forEach(cookieName => {
        document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.showify.pl`;
        document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      });
      console.log('Cleared cookies');

      // Przekieruj do strony głównej
      window.location.href = '/';
    } catch (error) {
      console.error('Main logout error:', error);
      window.location.href = '/';
    }
  };

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('pl-PL', options);
  };

  // Obsługa kliknięcia poza aktywne elementy interfejsu
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  

  return (
<Router>
  <Navigation
    activityIcon={activityIcon}
    handleSearchClick={handleSearchClick}
    handleMenuClick={handleMenuClick}
    showSearch={showSearch}
    showMenu={showMenu}
    navSearchRef={navSearchRef}
    menuRef={menuRef}
    handleLogout={handleLogout}
    openSearchModal={openSearchModal}
    closeSearchModal={closeSearchModal}
    isSearchModalOpen={isSearchModalOpen}
    user={user}
    searchIcon={searchIcon}
    messageIcon={messageIcon}
    starIcon={starIcon}
    bigFeaturedIcon={bigFeaturedIcon}
    bigRankIcon={bigRankIcon}
    bigRankAllIcon={bigRankAllIcon}
    bigDiaryIcon={bigDiaryIcon}
    bigHomeIcon={bigHomeIcon}
    bigLogoutIcon={bigLogoutIcon}
    changeLanguage={changeLanguage}
    t={t}
    heartIcon={heartIcon}
    userIcon={userIcon}
    settingsIcon={settingsIcon}
    chevronDownIcon={chevronDownIcon}
    chevronUpIcon={chevronUpIcon}
    calendarIcon={calendarIcon}
  />
  <Routes>
    <Route 
      path="/" 
      element={
        <HomePage 
          user={user} 
          setUser={setUser}
          shows={shows}
          isLoading={isLoading}
          currentLocation={currentLocation}
          handleChangeLocation={handleChangeLocation}
          getUserRatingForShow={getUserRatingForShow}
          showsByLocationSorted={showsByLocationSorted}
          starIcon={starIcon}
          rankIcon={rankIcon}
          searchIcon={searchIcon}
          infoIcon={infoIcon}
          calendarIcon={calendarIcon}
          bigFeaturedIcon={bigFeaturedIcon}
          bigRankIcon={bigRankIcon}
          bigRankAllIcon={bigRankAllIcon}
          bigDiaryIcon={bigDiaryIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          getRatingDescription={getRatingDescription}
          renderStarRating={renderStarRating}
          POLAND_CITIES={POLAND_CITIES}
          FEATURED_SHOWS={FEATURED_SHOWS}
          successIcon={successIcon}
          arrowLeftIcon={arrowLeftIcon}
          arrowRightIcon={arrowRightIcon}
          defaultPoster={defaultPoster}
          changeLanguage={changeLanguage}
          t={t}
          currentLocale={currentLocale}
          formatDate={formatDate}
          heartIcon={heartIcon}
          userIcon={userIcon}
          plusIcon={plusIcon}
          forumLogo={forumLogo}
          chevronsRightIcon={chevronsRightIcon}
          sunIcon={sunIcon}
          checkCircleIcon={checkCircleIcon}
        />
      } 
    /> 
    <Route 
      path="/signup" 
      element={<GoogleSignUp 
        t={t}
        shieldIcon={shieldIcon}
        keyIcon={keyIcon}
        userIcon={userIcon}
        setUser={setUser} />} 
    />
    <Route 
      path="/diary" 
      element={
        <Diary calendarIcon={calendarIcon}
          user={user} 
          setUser={setUser}
          shows={shows}
          isLoading={isLoading}
          currentLocation={currentLocation}
          handleChangeLocation={handleChangeLocation}
          getUserRatingForShow={getUserRatingForShow}
          showsByLocationSorted={showsByLocationSorted}
          starIcon={starIcon}
          rankIcon={rankIcon}
          searchIcon={searchIcon}
          bigFeaturedIcon={bigFeaturedIcon}
          bigRankIcon={bigRankIcon}
          bigRankAllIcon={bigRankAllIcon}
          bigDiaryIcon={bigDiaryIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          getRatingDescription={getRatingDescription}
          renderStarRating={renderStarRating}
          successIcon={successIcon}
          t={t}
          currentLocale={currentLocale}
          setIsLoading={setIsLoading}
          chevronUpIcon={chevronUpIcon}
          chevronDownIcon={chevronDownIcon}
         />
      } 
    />
    <Route 
      path="/addToDiary" 
      element={
        <AddToDiary
          starIcon={starIcon}
          rankIcon={rankIcon}
          searchIcon={searchIcon}
          plusIcon={plusIcon}       
          renderStarRating={renderStarRating}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          successIcon={successIcon}
          getRatingDescription={getRatingDescription}
          currentLocale={currentLocale}
          defaultPoster={defaultPoster}
          t={t}
          noteIcon={noteIcon}
         />
      } 
    />
    <Route 
      path="/shows/:slug" 
      element={
        <ShowPage 
          starIcon={starIcon}
          rankIcon={rankIcon}
          searchIcon={searchIcon}
          calendarIcon={calendarIcon}
          getRatingDescription={getRatingDescription}
          renderStarRating={renderStarRating}
          isLoading={isLoading}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          user={user}
          userIcon={userIcon}
          messageIcon={messageIcon}
          fileIcon={fileIcon}
          musicIcon={musicIcon}
          clockIcon={clockIcon}
          infoIcon={infoIcon}
          helpIcon={helpIcon}
          successIcon={successIcon}
          defaultPoster={defaultPoster}
          t={t}
          currentLocale={currentLocale}
          formatDate={formatDate}
          noteIcon={noteIcon}
        />
      } 
    />
        <Route 
      path="/person/:slug" 
      element={
        <PersonPage
          starIcon={starIcon}
          rankIcon={rankIcon}
          searchIcon={searchIcon}
          calendarIcon={calendarIcon}
          getRatingDescription={getRatingDescription}
          renderStarRating={renderStarRating}
          isLoading={isLoading}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          user={user}
          userIcon={userIcon}
          messageIcon={messageIcon}
          fileIcon={fileIcon}
          musicIcon={musicIcon}
          clockIcon={clockIcon}
          infoIcon={infoIcon}
          helpIcon={helpIcon}
          successIcon={successIcon}
          defaultPoster={defaultPoster}
          t={t}
          currentLocale={currentLocale}
          formatDate={formatDate}
          noteIcon={noteIcon}
          getUserRatingForShow={getUserRatingForShow}

        />
      } 
    />
    <Route 
      path="/best-played" 
      element={
        <BestPlayedPage 
          shows={shows}
          isLoading={isLoading}
          currentLocation={currentLocation}
          handleChangeLocation={handleChangeLocation}
          showsByLocationSorted={showsByLocationSorted}
          getUserRatingForShow={getUserRatingForShow}
          starIcon={starIcon}
          rankIcon={rankIcon}
          sortedShowsByLocation={sortedShowsByLocation}
          showsWithDynamicRank={showsWithDynamicRank}
          POLAND_CITIES={POLAND_CITIES}
          user={user}
          t={t}
          
        />
      } 
    />

<Route 
   path="/user/:googleId" 
   element={
     <UserPage 
       starIcon={starIcon}
       calendarIcon={calendarIcon}
       getRatingDescription={getRatingDescription}
       renderStarRating={renderStarRating}
       colorCircleCalendarIcon={colorCircleCalendarIcon}
       colorCircleStarIcon={colorCircleStarIcon}
       currentLocale={currentLocale}
       isLoading={isLoading}
       setIsLoading={setIsLoading}
       t={t}
       chevronUpIcon={chevronUpIcon}
       chevronDownIcon={chevronDownIcon}
       successIcon={successIcon}
       eyeIcon={eyeIcon}
       shareIcon={shareIcon}
       shareLinkIcon={shareLinkIcon}
       infoIcon={infoIcon}
     />
   } 
 />

<Route 
      path="/best-all-time" 
      element={
        <BestAllTime
          shows={shows}
          isLoading={isLoading}
          currentLocation={currentLocation}
          handleChangeLocation={handleChangeLocation}
          showsByLocationSorted={showsByLocationSorted}
          getUserRatingForShow={getUserRatingForShow}
          starIcon={starIcon}
          rankIcon={rankIcon}
          sortedShowsByLocation={sortedShowsByLocation}
          showsWithDynamicRank={showsWithDynamicRank}
          POLAND_CITIES={POLAND_CITIES}
          user={user}
          t={t}
        />
      } 
    />
    <Route 
      path="/about" 
      element={
        <About
          t={t}
        />
      } 
    />
        <Route 
      path="/feed" 
      element={
        <ActivityFeed
          t={t}
          user={user}
          formatDate={formatDate}
          calendarIcon={calendarIcon}
          renderStarRating={renderStarRating}
          infoIcon={infoIcon}
        />
      } 
    />
    <Route 
  path="/my-account" 
  element={
    <MyAccount 
    user={user}
      setIsLoading={setIsLoading}
      dragIcon={dragIcon}
      eyeIcon={eyeIcon}
      globeIcon={globeIcon}
      infoIcon={infoIcon}
  />} 
/>

<Route 
  path="/stats-rank" 
  element={
    <StatsRank
    user={user}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      dragIcon={dragIcon}
      eyeIcon={eyeIcon}
      globeIcon={globeIcon}
      starIcon={starIcon}
      chevronUpIcon={chevronUpIcon}
          chevronDownIcon={chevronDownIcon}
          shareIcon={shareIcon}
          shareLinkIcon={shareLinkIcon}
          downloadIcon={downloadIcon}
          settingsIcon={settingsIcon}
          infoIcon={infoIcon}
  />} 
/>
   
    <Route 
      path="/calendar" 
      element={
        <Calendar 
          POLAND_CITIES={POLAND_CITIES}
          t={t}
          arrowLeftIcon={arrowLeftIcon}
          arrowRightIcon={arrowRightIcon}
          infoIcon={infoIcon}
          currentLocale={currentLocale}
          starIcon={starIcon}
          chevronsRightIcon={chevronsRightIcon}
          sunIcon={sunIcon}
        />
      } 
    />
    <Route path="/calendar/day" element={<CalendarDayView 
    t={t}
    starIcon={starIcon}
    arrowLeftIcon={arrowLeftIcon}
    arrowRightIcon={arrowRightIcon}
    arrowLeftRegIcon={arrowLeftRegIcon}
    POLAND_CITIES={POLAND_CITIES}
    currentLocale={currentLocale}


     />} />
    <Route 
      path="*" 
      element={<Navigate to="/" />} 
    />
  </Routes>
  <BottomMenu
  homeIcon={homeIcon}
  compassIcon={compassIcon}
  bigDiaryIcon={bigDiaryIcon}


    openSearchModal={openSearchModal}
    closeSearchModal={closeSearchModal}
    isSearchModalOpen={isSearchModalOpen}
    
    searchIcon={searchIcon}
    starIcon={starIcon}
    t={t}
    userIcon={userIcon}
    defaultPoster={defaultPoster}
    colorCircleCalendarIcon={colorCircleCalendarIcon} 
colorCircleStarIcon={colorCircleStarIcon} 

successIcon={successIcon}
getRatingDescription={getRatingDescription}

setIsLoading={setIsLoading}
isLoading={isLoading}

   /> {/* Dodaj komponent BottomMenu */}
  <Footer
  t={t}
  changeLanguage={changeLanguage} />
        <CookieConsent
        t={t} />
</Router>

  );
};

export default App;
