import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BestPlayedPage = ({
  shows,
  POLAND_CITIES,
  isLoading,
  getUserRatingForShow,
  starIcon,
  user,
  rankIcon,
  t
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentCountry, setCurrentCountry] = useState(searchParams.get('country') || null);
  const [currentCity, setCurrentCity] = useState(searchParams.get('city') || null);
  const [posterLoadingStates, setPosterLoadingStates] = useState({});

  // Unikalne kraje z shows
  const countries = Array.from(new Set(shows.map(show => show.country)))
    .filter(country => country && country.trim() !== '');

  // Funkcja do filtrowania miast na podstawie spektakli dostępnych w bazie
  const getCitiesForCountry = (country) => {
    if (country === 'Polska') {
      const availableCities = POLAND_CITIES.filter(city =>
        shows.some(show => show.location === city)
      );
      return availableCities;
    }
    return Array.from(new Set(shows.filter(show => show.country === country).map(show => show.location)));
  };

  // Funkcje obsługujące filtrowanie
  const resetCountryFilter = () => {
    setCurrentCountry(null);
    setCurrentCity(null);
    setSearchParams({});
  };

  const resetCityFilter = () => {
    setCurrentCity(null);
    setSearchParams({ country: currentCountry });
  };

  const handleCountryChange = (country) => {
    setCurrentCountry(country);
    setCurrentCity(null);
    setSearchParams({ country });
  };

  const handleCityChange = (city) => {
    setCurrentCity(city);
    setSearchParams({ country: currentCountry, city });
  };

  const handlePosterLoad = (showId) => {
    setPosterLoadingStates(prev => ({
      ...prev,
      [showId]: false
    }));
  };

    // Dodaj efekt do synchronizacji z URL przy pierwszym renderowaniu
    useEffect(() => {
      const urlCountry = searchParams.get('country');
      const urlCity = searchParams.get('city');
  
      if (urlCountry) {
        setCurrentCountry(urlCountry);
        if (urlCity) {
          setCurrentCity(urlCity);
        }
      }
    }, [searchParams]);

  // Generowanie dynamicznego nagłówka
  const getHeaderText = () => {
    if (currentCity) {
      return t('best.best_shows.city', { city: currentCity });
    }
    if (currentCountry) {
      return t('best.best_shows.country', { country: currentCountry });
    }
    return t('best.best_shows.global');
  };

  // Filtrowanie spektakli
  const filteredShows = shows
  .filter(show => show.currentlyPlayed)
  .filter(show => show.globalRank > 0 && show.globalRank !== null)
  .filter(show => !currentCountry || show.country === currentCountry)
  .filter(show => !currentCity || show.location.split(',').map(city => city.trim()).includes(currentCity)) // Zmiana tutaj
  .sort((a, b) => b.avgRating - a.avgRating);


  const showsWithDynamicRank = filteredShows.map((show, index) => ({
    ...show,
    dynamicRank: index + 1,
  }));

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>    ;
  }

  return (
    <Container fluid className="px-3 main-container">
      <Row className="g-0 best-played mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          <h1 className="page-title">{t('best.title-currently')}</h1>

          {/* Filtracja po kraju */}
          <div className="filter-container mb-4">
            <Dropdown drop="auto">
              <Dropdown.Toggle variant="primary" id="dropdown-country">
                {currentCountry || t('best.choose-country')}
              </Dropdown.Toggle>

              <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                <Dropdown.Item onClick={resetCountryFilter}>{t('best.reset')}</Dropdown.Item>
                {countries
                  .sort((a, b) =>
                    shows.filter(show => show.country === b).length - 
                    shows.filter(show => show.country === a).length
                  ).map(country => (
                  <Dropdown.Item key={country} onClick={() => handleCountryChange(country)}>
                    {country}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* Filtracja po mieście (jeśli wybrano kraj) */}
            {currentCountry && (
              <Dropdown drop="auto">
                <Dropdown.Toggle variant="light" id="dropdown-city">
                  {currentCity || t('best.choose-city')}
                </Dropdown.Toggle>

                <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                  <Dropdown.Item onClick={resetCityFilter}>{t('best.reset')}</Dropdown.Item>
                  {getCitiesForCountry(currentCountry).map(city => (
                    <Dropdown.Item key={city} onClick={() => handleCityChange(city)}>
                      {city}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

          {/* Dynamiczny nagłówek */}
          <h2 className="dynamic-header mb-5 mt-5">{getHeaderText()}</h2>

        </Col>

        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
          {/* Wyświetlanie posortowanych spektakli */}
          {!isLoading && showsWithDynamicRank.length > 0 ? (
            showsWithDynamicRank.map(show => (
              <div key={show._id} className="show-card">
                <div className="show-poster">
                  <Link to={`/shows/${show.slug}`} className="show-link">
                    <div className="poster-container">
                      <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                      <img 
                        key={show._id}
                        src={show.poster && show.poster.includes('http') 
                          ? show.poster 
                          : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
                        alt={show.title}
                        className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                        onLoad={() => handlePosterLoad(show._id)}
                        onError={() => handlePosterLoad(show._id)}
                      />
                    </div>
                  </Link>
                  {user && getUserRatingForShow(show._id) ? (
  <div className="user-rating-badge">
    <span>{getUserRatingForShow(show._id)}</span>
  </div>
) : (
  <div className="user-rating-badge-star">
    <img src={starIcon} alt="Rating" className="rating-star-badge" />
  </div>
)}

                </div>

                <div className="show-info">
                  <div className="ranking">
                    <img src={rankIcon} alt="Rating" className="rank-icon" />
                    <span>{show.dynamicRank}.</span>
                  </div>
                  <div className="show-info-basic">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <h5 className="show-title">{show.title}</h5>
                    </Link>
                    <div className="theater-info">{show.theater}</div>
                    <div className="location">{show.location}</div>
                  </div>
                  <div className="rating">
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
                  </div>
                  <div className="tags">
                    {show.tags.map((tag, tagIndex) => (
                      <span key={tagIndex} className="tag">{tag}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-shows-av">{t('best.no-shows')}</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BestPlayedPage;
