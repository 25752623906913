import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Container, Row, Col, Card, Button, Spinner, Toast } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import html2canvas from 'html2canvas';


import socialTemplateBg from './img/social-template-bg.jpeg';
import whiteEyeIcon from './img/icons/white-eye.svg';


const StatsRank = ({ isLoading, setIsLoading, dragIcon, eyeIcon, globeIcon, starIcon,   chevronUpIcon,
  chevronDownIcon, shareIcon, shareLinkIcon, downloadIcon, settingsIcon, infoIcon }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userGoogleId, setUserGoogleId] = useState(null);
  const [topRatedShows, setTopRatedShows] = useState([]); // Nowy stan dla top 5 spektakli
  const [topShowsByAttendance, setTopShowsByAttendance] = useState([]); // Nowy stan dla najczęściej oglądanych spektakli
  const [rankedShows, setRankedShows] = useState([]);
  const [isDefaultOrder, setIsDefaultOrder] = useState(null); // Do śledzenia, czy lista jest w stanie domyślnym
  const [changesMade, setChangesMade] = useState(false); // Nowy stan do śledzenia zmian
  const [isSaving, setIsSaving] = useState(false); // Nowy stan dla spinnera zapisywania
  const [isEditMode, setIsEditMode] = useState(false); // Nowy stan dla trybu edycji
  const [expandedSections, setExpandedSections] = useState({
    topRated: true,  // Domyślnie rozwinięte
    topAttendance: true,  // Domyślnie rozwinięte
    ranked: true,  // Domyślnie rozwinięte
  });
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); 
  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false); 
  const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false); 
  const [showAllShows, setShowAllShows] = useState(false);


  const [totalEntries, setTotalEntries] = useState(null);
  const [followers, setFollowers] = useState([]);

  const [followingUsers, setFollowingUsers] = useState([]);
  const [followingStates, setFollowingStates] = useState({}); // Obiekt do przechowywania stanów
  
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);


      // Pobieranie listy osób, które śledzą bieżącego użytkownika
      useEffect(() => {
        const fetchFollowers = async () => {
          setIsLoading(true);
    
          const token = localStorage.getItem('token');
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/followers`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            setFollowers(response.data);  // Ustawienie stanu followers
          } catch (error) {
            console.error('Error fetching followers:', error);
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchFollowers();
      }, [setIsLoading]);  // Użycie pustej tablicy [] oznacza, że efekt zostanie wykonany tylko raz po załadowaniu komponentu
    
    
      
    
      useEffect(() => {
        const fetchFollowingUsers = async () => {
          setIsLoading(true);
    
          const token = localStorage.getItem('token');
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/following`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            setFollowingUsers(response.data);
      
            // Inicjalizacja domyślnego stanu `isFollowing` na `true` dla każdego użytkownika
            const initialFollowingState = response.data.reduce((acc, user) => {
              acc[user.googleId] = { isFollowing: true, loading: false }; // Zawsze `true` na start
              return acc;
            }, {});
      
            setFollowingStates(initialFollowingState);
          } catch (error) {
            console.error('Error fetching following users:', error);
          } finally {
            setIsLoading(false);
          }
        };
      
        fetchFollowingUsers();
      }, [setIsLoading]);

      const handleFollow = async (googleId) => {
        setFollowingStates(prevStates => ({
          ...prevStates,
          [googleId]: { ...prevStates[googleId], loading: true }
        })); // Ustaw spinner dla konkretnego użytkownika
      
        try {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/follow`,
            { followUserId: googleId },
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          
          setFollowingStates(prevStates => ({
            ...prevStates,
            [googleId]: { isFollowing: true, loading: false }
          })); // Zaktualizuj stan dla konkretnego użytkownika
        } catch (error) {
          console.error('Error following user:', error.response ? error.response.data : error.message);
          setFollowingStates(prevStates => ({
            ...prevStates,
            [googleId]: { ...prevStates[googleId], loading: false }
          })); // Wyłącz spinner w przypadku błędu
        }
      };
      
      const handleUnfollow = async (googleId) => {
        setFollowingStates(prevStates => ({
          ...prevStates,
          [googleId]: { ...prevStates[googleId], loading: true }
        })); // Ustaw spinner dla konkretnego użytkownika
      
        try {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/unfollow`,
            { unfollowGoogleId: googleId },
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          
          setFollowingStates(prevStates => ({
            ...prevStates,
            [googleId]: { isFollowing: false, loading: false }
          })); // Zaktualizuj stan dla konkretnego użytkownika
        } catch (error) {
          console.error('Error unfollowing user:', error.response ? error.response.data : error.message);
          setFollowingStates(prevStates => ({
            ...prevStates,
            [googleId]: { ...prevStates[googleId], loading: false }
          })); // Wyłącz spinner w przypadku błędu
        }
      };

  const handleNavigation = (path) => {
    window.location.href = path; // wymuś przejście na nowy adres
  };


  const toggleSection = (section) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };


  const handleLogout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }, [setUser]); // Zależność: setUser

  const [showToast, setShowToast] = useState(false);

  const handleCopyLink = () => {
    const profileLink = `https://showify.pl/user/${userGoogleId}`; // Generowanie linku
    navigator.clipboard.writeText(profileLink) // Kopiowanie linku do schowka
      .then(() => {
        setShowToast(true); // Wyświetlenie Toast po skopiowaniu
        setTimeout(() => setShowToast(false), 3000); // Automatyczne ukrycie Toast po 3 sekundach
      })
      .catch((err) => {
        console.error('Failed to copy link:', err);
      });
  };

    // Memoizacja funkcji processDiaryEntries
    const processDiaryEntries = useCallback((entries) => {
        if (!entries || entries.length === 0) {
          console.warn('Diary is empty or not loaded properly');
          return;
        }
    
        if (isDefaultOrder) {
          
          // Przetwarzanie dla domyślnego porządku
          const showRatings = {};
          entries.forEach(entry => {
            const showId = entry.show._id;
            const rating = entry.userRating;
            if (rating !== null) {
              if (!showRatings[showId]) {
                showRatings[showId] = { ratings: [], show: entry.show };
              }
              showRatings[showId].ratings.push(rating);
            }
          });
    
          const rankedShows = Object.values(showRatings).map(showData => {
            // Filtrujemy oceny, wykluczając null i 0
            const validRatings = showData.ratings.filter(rating => rating !== null && rating !== 0);
            
            // Sprawdzamy czy mamy jakieś ważne oceny
            const averageRating = validRatings.length > 0
              ? (validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length).toFixed(1)
              : 0;
    
            return {
              _id: showData.show._id,
              title: showData.show.title,
              theaterName: showData.show.theater,
              location: showData.show.location,
              country: showData.show.country,
              averageRating: averageRating,
              poster: showData.show.poster,
            };
          });
    
          const sortedRankedShows = rankedShows.sort((a, b) => b.averageRating - a.averageRating);
          setRankedShows(sortedRankedShows);
          console.log('Processed and Ranked Shows:', sortedRankedShows);
        } else {
          // Usuwamy duplikaty spektakli, aby każdy show był unikalny
          const uniqueShows = new Map();
          entries.forEach(entry => {
            const showId = entry.show._id.toString();
            if (!uniqueShows.has(showId)) {
              uniqueShows.set(showId, entry.show);
            }
          });
    
          const showOrderMap = new Map();
          user.rankedShows.forEach((showId, index) => {
            showOrderMap.set(showId.toString(), index);
          });
    
          // Sortujemy unikalne spektakle według kolejności z preferencji użytkownika
          const sortedByUserOrder = Array.from(uniqueShows.values())
            .filter(show => showOrderMap.has(show._id.toString()))
            .sort((a, b) => showOrderMap.get(a._id.toString()) - showOrderMap.get(b._id.toString()))
            .map(show => ({
              ...show,  // Zostaw wszystkie inne właściwości
              theaterName: show.theater  // Dodaj theaterName
            }))
          setRankedShows(sortedByUserOrder);
          console.log('Processed and Ranked Shows (user-defined order):', sortedByUserOrder);
        }
      }, [isDefaultOrder, user]);
    
      useEffect(() => {
            // Pobierz dane użytkownika z localStorage, jeśli są dostępne
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
        const fetchUserData = async () => {
          try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
    
            const userData = response.data;
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
    
            setIsDefaultOrder(userData.isDefaultRankedShows);
            setUserGoogleId(userData.googleId);

          } catch (error) {
            console.error('Error fetching user data:', error);
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchUserData();
      }, [setIsLoading]);
    
      useEffect(() => {
        if (isDefaultOrder !== null) {
          const fetchDiaryEntries = async () => {
            
            try {
              setIsLoading(true); // Ładowanie rozpoczyna się tutaj

              const diaryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
              });
              processDiaryEntries(diaryResponse.data);
            } catch (error) {
              if (error.response && error.response.status === 401) {
                handleLogout();
              }
              console.error('Error fetching diary entries:', error);
            } finally {
              setIsLoading(false); // Ładowanie zakończone
            }
          };
          fetchDiaryEntries();
        }
      }, [isDefaultOrder, processDiaryEntries, handleLogout, setIsLoading]);



  // Pobieranie danych o spektaklach i dzienniku użytkownika
  useEffect(() => {
    if (isDefaultOrder !== null) {
      const fetchDiaryEntries = async () => {
        try {
          const diaryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          processDiaryEntries(diaryResponse.data);
          calculateTopRatedShows(diaryResponse.data); // Wyznaczenie top 5 spektakli
          calculateTotalEntries(diaryResponse.data); // Wyznaczenie liczby wpisów wcześniejszych niż dzisiaj
          calculateTopShowsByAttendance(diaryResponse.data); // Wyznaczenie najczęściej oglądanych spektakli

        } catch (error) {
          if (error.response && error.response.status === 401) {
            handleLogout();
          }
          console.error('Error fetching diary entries:', error);
        }
      };
      fetchDiaryEntries();
    }
  }, [isDefaultOrder, processDiaryEntries, handleLogout]); // 'processDiaryEntries' added as a dependency

  const resetToDefaultOrder = async () => {
    const sortedByRating = [...topRatedShows].sort((a, b) => b.averageRating - a.averageRating);
    setRankedShows(sortedByRating.map(show => show.show));
    setIsDefaultOrder(true);
    setChangesMade(true); // Ustaw, że zmiany zostały dokonane
  };
  
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  

const handleDragEnd = (result) => {
  if (!result.destination || result.source.index === result.destination.index) {
    return;
  }

  const reorderedShows = reorder(rankedShows, result.source.index, result.destination.index);
  // Tutaj zmieniamy tylko lokalny stan
  setRankedShows(reorderedShows);
  // Ustawiamy flagi
  setIsDefaultOrder(false); 
  setChangesMade(true); 
};
  
  // Funkcja zapisu zmian do bazy
  const saveChanges = async () => {
    setIsSaving(true); // Pokazujemy spinner
  
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/rankedShows`, {
        userId: user._id,
        rankedShows: rankedShows.map(show => show._id),
        isDefaultRankedShows: isDefaultOrder, // Zapisanie, czy użytkownik chce powrócić do domyślnego porządku
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      console.log('Ranked shows successfully updated on server:', rankedShows);
      
      // Resetuj flagi zmian po zapisie
      setChangesMade(false);
      setIsEditMode(false); // Wyłączamy tryb edycji po zapisaniu

    } catch (error) {
      console.error('Failed to update ranking:', error);
    } finally {
      setIsSaving(false); // Wyłączamy spinner
    }
  };
  
  const handleEditClick = () => {
    setIsEditMode(true); // Aktywujemy tryb edycji
    setShowAllShows(true); // Pokaż wszystkie spektakle podczas edycji
    setIsDefaultOrder(false);
    setChangesMade(true);
  };
  
  // Dodaj nową funkcję
  const handleShowMore = () => {
    setShowAllShows(true);
  };
  


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signup');
      return;
    }
  }, [navigate]);

// Funkcja obliczająca średnią ocenę dla każdego show
const calculateTopRatedShows = (entries) => {
  // Grupowanie wpisów według showId
  const showRatings = {};

  entries.forEach(entry => {
    const showId = entry.show._id;
    const rating = entry.userRating;

    if (rating !== null && rating !== 0) { // Dodano warunek wykluczający oceny 0
      if (!showRatings[showId]) {
        showRatings[showId] = { ratings: [], show: entry.show };
      }
      showRatings[showId].ratings.push(rating);
    }
  });

  // Obliczenie średniej dla każdego show
  const averageRatings = Object.values(showRatings).map(showData => {
    // Filtrujemy oceny, wykluczając null i 0
    const validRatings = showData.ratings.filter(rating => rating !== null && rating !== 0);
    
    // Sprawdzamy czy mamy jakieś ważne oceny
    const averageRating = validRatings.length > 0
      ? (validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length).toFixed(1)
      : 0;

    return {
      show: showData.show,
      averageRating: averageRating,
    };
  });

  // Sortowanie według średniej oceny (od najwyższej do najniższej)
  const sortedShows = averageRatings
    .filter(show => show.averageRating > 0) // Filtrujemy tylko spektakle z oceną większą niż 0
    .sort((a, b) => b.averageRating - a.averageRating);

  // Pobieranie top 9
  setTopRatedShows(sortedShows.slice(0, 9));
};
    
 // Funkcja obliczająca spektakle z największą liczbą wpisów (attendance)
 const calculateTopShowsByAttendance = (entries) => {
    const attendanceCount = {};

    entries.forEach(entry => {
      const showId = entry.show._id;
      if (!attendanceCount[showId]) {
        attendanceCount[showId] = { count: 0, show: entry.show };
      }
      attendanceCount[showId].count += 1;
    });

    const sortedAttendance = Object.values(attendanceCount).sort((a, b) => b.count - a.count);
    setTopShowsByAttendance(sortedAttendance.slice(0, 9));
  };

  const calculateTotalEntries = (entries) => {
    // Pobierz dzisiejszą datę
    const today = new Date();
  
    // Przefiltruj wpisy, aby wziąć pod uwagę tylko te, które mają datę wcześniejszą niż dzisiaj
    const pastEntries = entries.filter(entry => {
      const entryDate = new Date(entry.dateWatched);  // Konwertujemy dateWatched na obiekt Date
      return entryDate < today;  // Sprawdzamy, czy data wpisu jest wcześniejsza niż dzisiaj
    });
  
    // Ustaw liczbę przeszłych wpisów
    setTotalEntries(pastEntries.length);
  };

  const countryEmojis = {
    Polska: '🇵🇱', // Polska
    UK: '🇬🇧', // Wielka Brytania
    USA: '🇺🇸'  // Stany Zjednoczone
  };
  
  const handleOpenShareModal = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleOpenFollowersModal = () => {
    setIsFollowersModalOpen(true);
  };

  const handleCloseFollowersModal = () => {
    setIsFollowersModalOpen(false);
  };

  const handleOpenFollowingModal = () => {
    setIsFollowingModalOpen(true);
  };

  const handleCloseFollowingModal = () => {
    setIsFollowingModalOpen(false);
  };

  const SocialTemplate = ({ topRatedShows, topShowsByAttendance, totalEntries, user }) => {
    return (
      <div id="composition" className="social-template">
        <img src={socialTemplateBg} alt="Poster" style={{ width: '100%', height: 'auto' }} />
  
        {/* Wprowadzenie z imieniem użytkownika */}
        <Row className="mb-2 justify-content-center diary intro">
          <Col>
            <h1>{t('my_account.social_template_image.title')} {user && user.name ? user.name.split(' ')[0] : ''}!</h1>
            <h2>{t('my_account.social_template_image.subtitle')}</h2>
          </Col>
        </Row>
  
        {/* Awatar użytkownika */}
        <Row className="mb-2 justify-content-center diary avatar">
          <Col>
            <img
              src={
                user?.avatarUrl && user.avatarUrl.includes('http')
                  ? user.avatarUrl
                  : `${process.env.REACT_APP_API_BASE_URL}/img${user?.avatarUrl || ''}`
              }
              alt="User Avatar"
              className="user-avatar"
            />
          </Col>
        </Row>
  
        {/* Sekcja ulubionych spektakli */}
        <Row className="mb-2 justify-content-center shows-container">
          <Col className="box diary fav-rank">
            <h3>{t('my_account.social_template_image.fav-shows')}</h3>
            <div className="shows-grid">
              {rankedShows.slice(0, 5).map((show, index) => (
                <div key={index} className="show-card">
                  <div className="show-poster">
                    <img
                      src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                      alt={show.title}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Col>
  
          {/* Sekcja najczęściej oglądanych spektakli */}
          <Col className="box diary top-attendance">
            <h3>{t('my_account.social_template_image.top-attendance')}</h3>
            <div className="shows-grid">
              {topShowsByAttendance.slice(0, 5).map((show, index) => (
                <div key={index} className="show-card">
                  <div className="show-poster">
                    <img
                      src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`}
                      alt={show.show.title}
                    />
                  </div>
                  <div className="show-info">
                    <img src={whiteEyeIcon} alt="Count" className="seen-eye white" />
                    {show.count} {show.count > 1 ? t('showpage.my_rating.seen-more') : t('showpage.my_rating.seen-1')}
                  </div>
                </div>
              ))}
            </div>
          </Col>
  
          {/* Sekcja najwyżej ocenionych spektakli */}
          <Col className="box diary top-rated">
            <h3>{t('my_account.social_template_image.top-ranked')}</h3>
            <div className="shows-grid">
              {topRatedShows.slice(0, 5).map((show, index) => (
                <div key={index} className="show-card">
                  <div className="show-poster">
                    <img
                      src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`}
                      alt={show.show.title}
                    />
                  </div>
                  <div className="show-info">
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    {show.averageRating}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
  
        {/* Podsumowanie liczby wejść do teatru */}
        <Row className="mb-2 justify-content-center diary total-entries">
          <Col>
          {user && user.name.endsWith('a')
        ? t('my_account.social_template_image.entries-claim-intro-female')
        : t('my_account.social_template_image.entries-claim-intro-male')} <span className="entries"><span className="number">{totalEntries}</span> {totalEntries > 1 ? t('showpage.my_rating.seen-more') : t('showpage.my_rating.seen-1')}</span>. {t('my_account.social_template_image.and-you')}
          </Col>
        </Row>
      </div>
    );
  };



  const generateImage = async () => {
    setIsGeneratingImage(true); // Ustawiamy stan na true przed rozpoczęciem generowania
    
    try {
      const element = document.getElementById('composition');
      const canvas = await html2canvas(element, {
        useCORS: true,
        logging: true,
      });
      
      // Konwertujemy canvas na JPEG
      const imgData = canvas.toDataURL('image/jpeg', 0.85);
      
      // Tworzymy link do pobrania
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'profil-teatralny.jpg';
      link.click();
    } catch (error) {
      console.error('Błąd podczas generowania obrazka:', error);
    } finally {
      setIsGeneratingImage(false); // Zawsze wyłączamy spinner na końcu
    }
  };
  
  
  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  // Renderowanie komponentu
  return (
    
    <Container fluid className="px-3 main-container my-account stats-rank">

      <Row className="mb-4 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          <h1 className="page-title">{t('my_account.stats_title')}</h1>

       


          <div className="account-intro">
            <img
              src={user?.avatarUrl && user.avatarUrl.includes('http')
                ? user.avatarUrl
                : `${process.env.REACT_APP_API_BASE_URL}/img${user?.avatarUrl || ''}`}
              alt="User Avatar"
              className="user-avatar"
            />
            
            <h2 className="mb-1 mt-3">{user && user.name ? user.name : ''}</h2>

<div className="following-followers mt-4 mb-2">

<div className="f-left">
<div className="f-box" onClick={handleOpenFollowingModal}>
  <div className="f-label">{t('my_account.following')}</div>
  <div className="f-number">{followingUsers.length}</div>
</div>
</div>

<div className="f-box" onClick={handleOpenFollowersModal}>
<div className="f-label">{t('my_account.followers')}</div>
<div className="f-number">{followers.length}</div>
</div>

  </div>





            <div className="my-shows-buttons mt-4 mb-2">
          {/* Przycisk dodawania spektaklu do dziennika */}

          
            <Button variant="primary" onClick={handleOpenShareModal} className="add-show-button">
            <img src={shareIcon} alt="Best of all time" className="quick-icon white" />
              {t('my_account.share-summary')}
            </Button>
        
          <Button variant="light" className="add-show-button" onClick={handleCopyLink}>
        <img src={shareLinkIcon} alt="Best of all time" className="quick-icon" />
        {t('my_account.share-link')}
      </Button>

            {/* Toast component */}
            {showToast && (
        <Toast onClose={() => setShowToast(false)} show={showToast} className="custom-toast" delay={3000} autohide>
          <Toast.Body>{t('my_account.link_copied')}</Toast.Body>
        </Toast>
      )}

      <Button variant="light" className="add-show-button" as={Link} to="/my-account">
        <img src={settingsIcon} alt="Best of all time" className="quick-icon" />
        {t('my_account.settings-link')}
      </Button>


        </div>
        </div>



        </Col>
      </Row>



      {/* Najlepiej Ocenione Sekcja */}
      <Row className="mb-2 justify-content-center diary">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <div className="diary-section">
          <div 
              className={`diary-section-top ${expandedSections.topRated ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('topRated')}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <h2 className="section-title mb-0 pb-0">{t('my_account.top_shows')}</h2>
            <div className="arrow-icon">
                {expandedSections.topRated ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
          </div>
          <div className={`diary-section-grid ${expandedSections.topRated ? 'expanded' : 'collapsed'}`}   >

          {topRatedShows.length === 0 && (
            <div className="all-shows-info justify-content-center mt-2">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('stats-rank.empty-top-rated')}
            </div>
          )}

          {expandedSections.topRated && (
            <div className="shows-grid">
              {topRatedShows.map((show, index) => (
                <div key={index} className="show-card">
                  <Link to={`/shows/${show.show.slug}`} className="show-link">
                    <div className="show-poster">
                      <img 
                        src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`} 
                        alt={show.show.title} 
                      />
                    </div>
                  </Link>
                  <div className="show-info">
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    <span className="show-rating">  {show.averageRating}</span>   
                    <p className="show-rating-sub">{t('my_account.rating')}</p>   
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
          </div>
        </Col>
      </Row>

      {/* Najczęściej Oglądane Sekcja */}
      <Row className="mb-2 justify-content-center diary">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <div className="diary-section">
          <div 
              className={`diary-section-top ${expandedSections.topAttendance ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('topAttendance')}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <h2 className="section-title mb-0 pb-0">{t('my_account.top_shows_attendance')}</h2>
            <div className="arrow-icon">
                {expandedSections.topAttendance ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
          </div>
          <div className={`diary-section-grid ${expandedSections.topAttendance ? 'expanded' : 'collapsed'}`}   >

          {topShowsByAttendance.length === 0 && (
            <div className="all-shows-info justify-content-center mt-2">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('stats-rank.empty-top-attendance')}
            </div>
          )}

          {expandedSections.topAttendance && (
            <div className="shows-grid">
              {topShowsByAttendance.map((show, index) => (
                <div key={index} className="show-card">
                  <Link to={`/shows/${show.show.slug}`} className="show-link">
                    <div className="show-poster">
                      <img 
                        src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`} 
                        alt={show.show.title} 
                      />
                    </div>
                  </Link>
                  <div className="show-info">
                    <img src={eyeIcon} alt="Count" className="seen-eye" />
                    <span className="show-rating">                    
                      {show.count} {show.count > 1 ? t('showpage.my_rating.seen-more') : t('showpage.my_rating.seen-1')}
                    </span>   
                    <p className="show-rating-sub">{t('my_account.entries')}</p>   
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
          </div>
        </Col>
      </Row>

      {/* Mój prywatny ranking */}
      <Row id="fav-rank" className="mb-5 justify-content-center stats-rank diary">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <div className="diary-section">
          <div 
              className={`diary-section-top ${expandedSections.ranked ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('ranked')}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <h2 className="section-title mb-0 pb-0">{t('my_account.ranked_shows')}</h2>
            <div className="arrow-icon">
                {expandedSections.ranked ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
          </div>
          <div className={`diary-section-grid ${expandedSections.ranked ? 'expanded' : 'collapsed'}`}   >

          {topRatedShows.length === 0 && (
            <div className="all-shows-info justify-content-center mt-2">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('stats-rank.empty-top-rated')}
            </div>
          )}

          {expandedSections.ranked && topRatedShows.length > 0 && (
            <>
                      <div className="priv-rank-container">

              <div className="rank-buttons">
              {!isEditMode ? (
                <Button onClick={handleEditClick}>{t('my_account.edit_ranking')}</Button>
              ) : (
                <>
                  <Button 
                    variant="primary" 
                    onClick={saveChanges} 
                    disabled={!changesMade || isSaving}
                  >
                    {isSaving ? <Spinner animation="border" size="sm" /> : t('my_account.save_changes')}
                  </Button>

                  <Button 
                    variant="danger" 
                    onClick={resetToDefaultOrder} 
                    disabled={isDefaultOrder}
                  >
                    {t('my_account.reset_default_order')}
                  </Button>
                </>
              )}
              </div>

{rankedShows && rankedShows.length > 0 && (
  <>
                <DragDropContext onDragEnd={isEditMode ? handleDragEnd : null}>
                  <Droppable droppableId="rankedShows">
                    {(provided, { placeholder = null } = {}) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {(showAllShows ? rankedShows : rankedShows.slice(0, 10)).map((show, index) => (
                          <Draggable key={show._id} draggableId={show._id} index={index} isDragDisabled={!isEditMode}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="draggable-show"
                            >
                              <Card className="">
                                <Card.Body className="d-flex align-items-start">
                                  <div className="rank-item d-flex">
                                    <div className="rank-number">
                                      {index + 1}
                                    </div>
                                    <div className="show-poster">
                                      {show.poster && (
                                        <img
                                          src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                                          alt={show.title}
                                          className="poster-img"
                                        />
                                      )}
                                    </div>
                                    <div className="rank-title-theater flex-grow-1">
                                      <Card.Title className="show-title my-1 text-wrap">{show.title}</Card.Title>
                                      {show.theaterName && (
                                        <Card.Subtitle className="theater-info my-1 text-wrap">{show.theaterName}</Card.Subtitle>
                                      )}
                                      {show.location && (
                                        <div className="location my-1">
                                          {countryEmojis[show.country] && (
                                            <span>{countryEmojis[show.country]} </span> // Dodaj flagę przed lokalizacją
                                          )}
                                          {show.location}
                                        </div>
                                      )}
                                    </div>
                                    {isEditMode && (
                                      <div className="drag-icon-container" {...provided.dragHandleProps}>
                                        <img src={dragIcon} alt="Drag" className="drag-icon" />
                                      </div>
                                    )}
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                        
                        ))}
                        {placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {!showAllShows && rankedShows.length > 10 && (
        <Button 
          variant="light" 
          onClick={handleShowMore} 
          className="show-more-button mt-3"
        >
          {t('buttons.show_more')} ({rankedShows.length - 10})
        </Button>
      )}
    </>
              )}
              </div>

</>
)}
</div>
</div>
        </Col>
      </Row>

      <Row className="mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <Button 
      variant="primary" 
      className="see-rank-button" 
      onClick={() => handleNavigation('/diary')}
    >
      {t('my_account.see-diary')}
    </Button>
        </Col>
        </Row>


            {/* Modal udostępniania */}
            <Modal show={isShareModalOpen} onHide={handleCloseShareModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-calendar">
          <h1 className="mb-4">{t('my_account.social_template.title')}</h1>

          <ol>
            <li>{t('my_account.social_template.rate')}</li>
            <li>{t('my_account.social_template.rank')}</li>
            <li><Trans i18nKey="my_account.social_template.generate"></Trans></li>
            <li>{t('my_account.social_template.invite')}</li>
          </ol>

          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button 
  variant="primary" 
  className="see-rank-button mt-2 generate-rank" 
  onClick={generateImage}
  disabled={isGeneratingImage} // Wyłącz przycisk podczas generowania
>
  {isGeneratingImage ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <>
      <img src={downloadIcon} alt="Download" className="quick-icon white" />
      {t('my_account.social_template.download')}
    </>
  )}
</Button>
          <Button variant="light" onClick={handleCloseShareModal}>{t('modals.show_modal.cancel_button')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal obserwujących */}
      <Modal show={isFollowersModalOpen} onHide={handleCloseFollowersModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-calendar modal-followers">
            <h1 className="mb-4">{t('my_account.followers')}</h1>

            {followers.length === 0 && (
            <div className="all-shows-info mt-4">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('stats-rank.empty-followers')}
            </div>
          )}

            {followers.map(user => (
              <Card key={user.googleId}>
                <Card.Body className="users-list justify-content-between align-items-center">
                  <div className="users-list-main d-flex justify-content-between align-items-center">
                    <Link to={`/user/${user.googleId}`} className="user-link">
                      <div className="users-list-left align-items-center">
                        <img src={user.avatarUrl} alt={user.name} className="user-avatar" />
                        <p className="user-name">
                          {user.name}
                        </p>
                      </div>
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            ))}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseFollowersModal}>{t('modals.show_modal.cancel_button')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal obserwowanych */}
      <Modal show={isFollowingModalOpen} onHide={handleCloseFollowingModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-calendar modal-followers">
            <h1 className="mb-4">{t('my_account.following')}</h1>

            {followingUsers.length === 0 && (
            <div className="all-shows-info mt-4">
              <img src={infoIcon} alt="Info" className="info-icon" />
              {t('stats-rank.empty-following-users')}
            </div>
          )}

            {followingUsers.map(user => (
              <Card key={user.googleId}>
                <Card.Body className="users-list justify-content-between align-items-center">
                  <div className="users-list-main d-flex justify-content-between align-items-center">
                    <Link to={`/user/${user.googleId}`} className="user-link">
                      <div className="users-list-left align-items-center">
                        <img src={user.avatarUrl} alt={user.name} className="user-avatar" />
                        <p className="user-name">
                          {user.name}
                        </p>
                      </div>
                    </Link>
                    <div className="div-unfollow">
                      {followingStates[user.googleId]?.isFollowing ? (
                        <Button 
                          variant="danger" 
                          className="btn-smallest"
                          onClick={() => handleUnfollow(user.googleId)} 
                          disabled={followingStates[user.googleId]?.loading}
                        >
                          {followingStates[user.googleId]?.loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            t('my_account.unfollow')
                          )}
                        </Button>
                      ) : (
                        <Button 
                          variant="success" 
                          className="btn-smallest"
                          onClick={() => handleFollow(user.googleId)} 
                          disabled={followingStates[user.googleId]?.loading}
                        >
                          {followingStates[user.googleId]?.loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            t('my_account.follow')
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseFollowingModal}>{t('modals.close_button')}</Button>
        </Modal.Footer>
      </Modal>

      {/* Ukryty komponent SocialTemplate */}
      <Row className="hidden">
        <Col>
          <SocialTemplate 
            topRatedShows={topRatedShows}
            topShowsByAttendance={topShowsByAttendance}
            totalEntries={totalEntries}
            user={user}
          />
        </Col>
      </Row>

    </Container>
  );
};

export default StatsRank;
