import React from 'react';
import { NavLink } from 'react-router-dom';
import SearchModal from './SearchModal';

const BottomMenu = ({
  homeIcon, compassIcon, bigDiaryIcon, openSearchModal, 
  isSearchModalOpen, closeSearchModal, starIcon, searchIcon, t, userIcon, defaultPoster, currentLocale, colorCircleCalendarIcon, colorCircleStarIcon, successIcon, getRatingDescription,
  isLoading, setIsLoading
}) => {


  

  const handleMenuClick = () => {
    closeSearchModal();  // Zamknij modal przy każdym kliknięciu w menu
    window.scrollTo(0, 0); // Przewiń stronę do góry
  };

  return (
    <div className="bottom-menu">
      <NavLink 
        to="/" 
        className={({ isActive }) => 
          isSearchModalOpen ? "menu-item" : isActive ? "menu-item active" : "menu-item"
        }
        onClick={handleMenuClick}
      >
        <img src={homeIcon} alt="Home" className="menu-icon" />
        <span className="menu-text">{t('bottom-menu.home')}</span>
      </NavLink>

      <NavLink 
        to="/diary" 
        className={({ isActive }) => 
          isSearchModalOpen ? "menu-item" : isActive ? "menu-item active" : "menu-item"
        }
        onClick={handleMenuClick}
      >
        <img src={bigDiaryIcon} alt="Diary" className="menu-icon" />
        <span className="menu-text">{t('bottom-menu.diary')}</span>
      </NavLink>

      <NavLink 
        to="/stats-rank" 
        className={({ isActive }) => 
          isSearchModalOpen ? "menu-item" : isActive ? "menu-item active" : "menu-item"
        }
        onClick={handleMenuClick}
      >
        <img src={userIcon} alt="Diary" className="menu-icon" />
        <span className="menu-text">{t('bottom-menu.profile')}</span>
      </NavLink>

      <NavLink 
        to="/best-played" 
        className={({ isActive }) => 
          isSearchModalOpen ? "menu-item" : isActive ? "menu-item active" : "menu-item"
        }
        onClick={handleMenuClick}
      >
        <img src={compassIcon} alt="Best Played" className="menu-icon" />
        <span className="menu-text">{t('bottom-menu.explore')}</span>
      </NavLink>

      <div
        onClick={() => openSearchModal()}
        className={`menu-item cursor-pointer ${isSearchModalOpen ? "active" : ""}`}
      >
        <img src={searchIcon} alt="Search" className="menu-icon" />
        <span className="menu-text">{t('bottom-menu.search')}</span>
      </div>

      <SearchModal
        isOpen={isSearchModalOpen}
        onRequestClose={closeSearchModal}
        starIcon={starIcon}
        searchIcon={searchIcon}
        defaultPoster={defaultPoster}
        colorCircleCalendarIcon={colorCircleCalendarIcon}
        colorCircleStarIcon={colorCircleStarIcon}
        successIcon={successIcon}
getRatingDescription={getRatingDescription}
currentLocale={currentLocale}
setIsLoading={setIsLoading}
isLoading={isLoading}

      />
    </div>
  );
};

export default BottomMenu;
