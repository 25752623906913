import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { format } from 'date-fns';


const CalendarDayView = ({ t, starIcon, arrowLeftIcon, arrowRightIcon, arrowLeftRegIcon, POLAND_CITIES, currentLocale }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentCountry, setCurrentCountry] = useState(searchParams.get('country') || null);
  const [currentCity, setCurrentCity] = useState(searchParams.get('city') || null);
  const [showAllCities, setShowAllCities] = useState(false);
  const navigate = useNavigate();
  
  // Memoizacja tworzenia obiektu date z uwzględnieniem strefy czasowej
  const date = useMemo(() => {
    const paramDate = new Date(searchParams.get('date'));
    // Ustawienie godziny na początek dnia w lokalnej strefie czasowej
    paramDate.setHours(0, 0, 0, 0);
    return paramDate;
  }, [searchParams]);

  // Memoizacja stringa daty z uwzględnieniem strefy czasowej
  const dateString = useMemo(() => {
    if (!date) return null;
    // Tworzenie daty w UTC
    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0, 0, 0, 0
    ));
    return utcDate.toISOString();
  }, [date]);

  useEffect(() => {
    let isSubscribed = true;

    const loadEvents = async () => {
      if (!dateString) return;
      
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/day`, {
          params: {
            date: dateString,
            country: currentCountry,
            city: currentCity
          }
        });
        if (isSubscribed) {
          // Sortowanie najpierw po miastach, potem po tytułach
          const sortedEvents = response.data.sort((a, b) => {
            // Pobierz lokalizacje (z uwzględnieniem altLocation)
            const locationA = a.altLocation || a.show.location;
            const locationB = b.altLocation || b.show.location;
            
            // Najpierw sortuj po miastach
            const cityCompare = locationA.localeCompare(locationB, undefined, {sensitivity: 'base'});
            
            // Jeśli miasta są różne, zwróć wynik porównania miast
            if (cityCompare !== 0) {
              return cityCompare;
            }
            
            // Jeśli miasta są takie same, sortuj po tytułach
            return a.show.title.localeCompare(b.show.title, undefined, {sensitivity: 'base'});
          });
          setEvents(sortedEvents);
        }
      } catch (error) {
        console.error('Błąd podczas ładowania wydarzeń:', error);
      } finally {
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    };

    loadEvents();

    return () => {
      isSubscribed = false;
    };
  }, [dateString, currentCountry, currentCity]);

  const formatDate = (date) => {
    if (!date) return '';
    
    return format(date, 'EEEE, d MMMM yyyy', { 
      locale: currentLocale
    });
  };

  const navigateToDay = (days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    // Ustawienie godziny na początek dnia
    newDate.setHours(0, 0, 0, 0);
    const params = new URLSearchParams(searchParams);
    params.set('date', newDate.toISOString());
    navigate(`/calendar/day?${params.toString()}`);
  };

  // Funkcje obsługujące filtrowanie
  const resetCountryFilter = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('country');
    params.delete('city');
    setCurrentCountry(null);
    setCurrentCity(null);
    setSearchParams(params);
  };

  const resetCityFilter = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('city');
    setCurrentCity(null);
    setSearchParams(params);
  };

  const handleCountryChange = (country) => {
    const params = new URLSearchParams(searchParams);
    params.set('country', country);
    params.delete('city');
    setCurrentCountry(country);
    setCurrentCity(null);
    setSearchParams(params);
  };

  const handleCityChange = (city) => {
    const params = new URLSearchParams(searchParams);
    params.set('city', city);
    setCurrentCity(city);
    setSearchParams(params);
  };

  // Funkcje do filtrowania miast
  const getCitiesForCountry = (country) => {
    if (!events || events.length === 0) return [];
    
    if (country === 'Polska') {
      const availableCities = POLAND_CITIES.filter(city =>
        events.some(event => 
          event.altLocation === city || (!event.altLocation && event.show.location === city)
        )
      );
      return availableCities;
    }
    return Array.from(new Set(events.flatMap(event => {
      const cities = [];
      if (event.show.country === country) {
        if (event.altLocation) {
          cities.push(event.altLocation);
        } else {
          cities.push(event.show.location);
        }
      }
      return cities;
    })));
  };

  const getAllCitiesForCountry = (country) => {
    if (!events || events.length === 0) return [];

    if (country === 'Polska') {
      return POLAND_CITIES;
    }
    return Array.from(new Set(events.flatMap(event => {
      const cities = [];
      if (event.show.country === country) {
        if (event.altLocation) {
          cities.push(event.altLocation);
        } else {
          cities.push(event.show.location);
        }
      }
      return cities;
    })));
  };

  const renderCityItems = () => {
    if (!currentCountry) return null;
    
    const filteredCities = getCitiesForCountry(currentCountry);
    const allCities = getAllCitiesForCountry(currentCountry);
    const citiesToShow = showAllCities ? allCities : filteredCities;

    return (
      <>
        <Dropdown.Item onClick={resetCityFilter}>{t('best.reset')}</Dropdown.Item>
        {citiesToShow.map(city => (
          <Dropdown.Item key={city} onClick={() => handleCityChange(city)}>
            {city}
          </Dropdown.Item>
        ))}
        {!showAllCities && allCities.length > filteredCities.length && (
          <Dropdown.Item 
            onClick={() => setShowAllCities(true)}
            className="show-more-item"
          >
            {t('calendar.show-more')}
          </Dropdown.Item>
        )}
      </>
    );
  };

  const getShowTimesForDay = (event) => {
    return event.dates.filter(date => {
      const eventDate = new Date(date);
      const currentDate = new Date(dateString);
      return eventDate.toDateString() === currentDate.toDateString();
    }).length;
  };

  return (
    <Container fluid className="px-3 main-container calendar-day-view">
      <Row className="g-0 best-played mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">

          
          <div className="day-view-header">
            <div className="day-view-buttons">
            <Button 
              variant="light" 
              onClick={() => navigate('/calendar')}
              className="quick-button"
            >
              <img src={arrowLeftRegIcon} title="Previous" alt="Previous" className="quick-icon" />
              {t('calendar.back-to-calendar')}
            </Button>
            
            <div className="filter-container">
              <Dropdown drop="auto">
                <Dropdown.Toggle variant="primary" id="dropdown-country">
                  {currentCountry || t('calendar.select-country')}
                </Dropdown.Toggle>
                <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                  <Dropdown.Item onClick={resetCountryFilter}>{t('best.reset')}</Dropdown.Item>
                  {events.length > 0 && Array.from(new Set(events.map(event => event.show.country)))
                    .filter(country => country && country.trim() !== '')
                    .sort((a, b) =>
                      events.filter(event => event.show.country === b).length - 
                      events.filter(event => event.show.country === a).length
                    ).map(country => (
                      <Dropdown.Item key={country} onClick={() => handleCountryChange(country)}>
                        {country}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              

              {currentCountry && (
                <Dropdown drop="auto">
                  <Dropdown.Toggle variant="light" id="dropdown-city">
                    {currentCity || t('calendar.select-city')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                    {renderCityItems()}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              </div>
            </div>
          </div>

          <div className="day-navigation mb-4">

            <img src={arrowLeftIcon} title="Previous" alt="Previous" onClick={() => navigateToDay(-1)} className="nav-arrow" />

            <h2 className="current-date">{formatDate(date)}</h2>

            <img src={arrowRightIcon} title="Next" alt="Next" onClick={() => navigateToDay(1)} className="nav-arrow" />


          </div>

          {isLoading ? (
            <div className="text-center my-5">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="events-list">
              {events.length > 0 ? events.map((event) => {
                const showTimesCount = getShowTimesForDay(event);
                
                return (
                  <Link to={`/shows/${event.show.slug}`} className="poster-link" key={event.show._id}>
                    <div className="event-card">
                      <Link to={`/shows/${event.show.slug}`} className="poster-link">
                        <img 
                          src={event.show.poster && event.show.poster.includes('http') ? event.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${event.show.poster || ''}`}
                          alt={event.show.title} 
                          className="show-poster"
                        />
                      </Link>
                      <div className="show-info">
                        <div className="show-location">{event.altLocation || event.show.location}</div>
                        <div className="show-t-r">
                          <Link to={`/shows/${event.show.slug}`} className="show-title">
                            <h3>{event.show.title}</h3>
                          </Link>
                          {event.show.avgRating > 0 && (
                            <div className="show-rating">
                              <img src={starIcon} alt="Rating" className="rating-star" />
                              <span>{event.show.avgRating.toFixed(1)}</span>
                            </div>
                          )}
                        </div>
                        <div className="show-theater">{event.show.theater}</div>
                        {showTimesCount > 1 && (
                          <div className="multiple-shows-tag">
                            {showTimesCount} {t('calendar.shows-today')}
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                );
              }) : (
                <p className="no-events">{t('calendar.no-events')}</p>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CalendarDayView; 