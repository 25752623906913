import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Spinner, Modal, Card, Container, Row, Col, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

import ShowModal from './ShowModal';
import awardsPlebiscytIcon from './img/awards-plebiscyt.jpg';
import awardsKiepuryIcon from './img/awards-kiepury.jpg';



const ShowPage = ({ 
  rankIcon, 
  starIcon, 
  calendarIcon,
  getRatingDescription,
  renderStarRating,
  colorCircleCalendarIcon,
  colorCircleStarIcon,
  user,
  userIcon,
  messageIcon,
  fileIcon,
  musicIcon,
  clockIcon,
  helpIcon,
  t,
  currentLocale,
  formatDate,
  successIcon,
  noteIcon,
  defaultPoster
}) => {
  const { slug } = useParams(); 

  // Najpierw wszystkie stany
  const [show, setShow] = useState(null); 
  const [userDiaryEntries, setUserDiaryEntries] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [isInWishList, setIsInWishList] = useState(false); 
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isAwardsModalOpen, setIsAwardsModalOpen] = useState(false); 
  const [isWishlistButtonLoading, setIsWishlistButtonLoading] = useState(false);
  const [locationRanks, setLocationRanks] = useState({});
  const [awards, setAwards] = useState([]);
  const [calendarDates, setCalendarDates] = useState([]);
  const [showAllMonths, setShowAllMonths] = useState(false);

  const navigate = useNavigate();

  // Przenosimy funkcję groupDatesByMonth przed jej użyciem w useEffect
  const groupDatesByMonth = (events) => {
    if (!events || !Array.isArray(events)) {
      console.log('Brak wydarzeń lub nieprawidłowy format:', events);
      return {};
    }
    
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    
    // Najpierw tworzymy płaską listę wszystkich dat ze wszystkich wydarzeń
    const allDates = events.reduce((acc, event) => {
      if (!event.dates || !Array.isArray(event.dates)) return acc;
      
      const eventDates = event.dates.map(dateStr => ({
        date: new Date(new Date(dateStr).setHours(0, 0, 0, 0)),
        altLocation: event.altLocation,
        defaultLocation: event.show?.location
      }));
      
      return [...acc, ...eventDates];
    }, []);
    
    // Filtrujemy przeszłe daty i sortujemy wszystkie daty rosnąco
    const filteredAndSortedDates = allDates
      .filter(dateInfo => dateInfo.date >= today)
      .sort((a, b) => a.date - b.date);
    
    // Grupujemy posortowane daty po miesiącach
    return filteredAndSortedDates.reduce((acc, dateInfo) => {
      const monthKey = format(dateInfo.date, 'MMMM yyyy', { locale: pl });
      if (!acc[monthKey]) {
        acc[monthKey] = [];
      }
      acc[monthKey].push(dateInfo);
      return acc;
    }, {});
  };

  const handleOpenAwardsModal = () => {
    setIsAwardsModalOpen(true);
  };

  const handleCloseAwardsModal = () => {
    setIsAwardsModalOpen(false);
  };


useEffect(() => {
  const fetchShow = async () => {
    try {
      const showResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows/${slug}`);
      setShow(showResponse.data || null);

      const token = localStorage.getItem('token');
      if (token) {
        const diaryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserDiaryEntries(diaryResponse.data.filter(entry => entry.show?._id === showResponse.data?._id));

        const userResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const isInList = userResponse.data.wishList.includes(showResponse.data?._id);
        setIsInWishList(isInList);
      }
    } catch (error) {
      console.error('Error fetching the show or diary data:', error);
      setShow(null);
    } finally {
      setLoading(false);
    }
  };

  fetchShow();
}, [slug]);

useEffect(() => {
  if (show?.location && show?.slug) {
    const locations = show?.location.split(', ');
    
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ranks-by-locations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        slug: show?.slug,
        locations: locations
      })
    })
    .then(res => res.json())
    .then(data => {
      const ranks = {};
      data.forEach(item => {
        ranks[item.location] = item.rank;
      });
      setLocationRanks(ranks);
      console.log(ranks)
    });
  }
}, [show]);

useEffect(() => {
  const fetchAwards = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/awards/show/${show._id}`);
      setAwards(response.data);
    } catch (error) {
      console.error('Błąd podczas pobierania nagród:', error);
    }
  };

  if (show?._id) {
    fetchAwards();
  }
}, [show]);

useEffect(() => {
  const fetchCalendarDates = async () => {
    try {
      console.log('Fetching calendar dates for slug:', slug);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/show/${slug}`);
      console.log('Calendar API response:', response.data);
      setCalendarDates(response.data);
    } catch (error) {
      console.error('Error fetching calendar dates:', error);
    }
  };

  if (slug) {
    fetchCalendarDates();
  }
}, [slug]);

// Dodaj monitoring zmian calendarDates
useEffect(() => {
  console.log('calendarDates updated:', calendarDates);
  if (calendarDates && calendarDates.length > 0) {
    console.log('Sample event structure:', calendarDates[0]);
    const groupedDates = groupDatesByMonth(calendarDates);
    console.log('Grouped dates:', groupedDates);
  }
}, [calendarDates]);

  // Funkcje obsługujące dodawanie i usuwanie spektaklu z listy życzeń
  const handleAddToWishList = async () => {
    try {
      setIsWishlistButtonLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/wishlist/add`, 
        { showId: show?._id }, 
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setIsInWishList(true);
    } catch (err) {
      console.error('Error adding to wish list:', err.response?.data || err);
    } finally {
      setIsWishlistButtonLoading(false);
    }
  };
  
  const handleRemoveFromWishList = async () => {
    try {
      setIsWishlistButtonLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/wishlist/remove`, 
        { showId: show?._id }, 
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setIsInWishList(false);
    } catch (err) {
      console.error('Error removing from wish list:', err);
    } finally {
      setIsWishlistButtonLoading(false);
    }
  };

  const handleNavigation = (path) => {
    window.location.href = path; // wymuś przejście na nowy adres
  };

const getUserRatingForShow = () => {
  if (userDiaryEntries.length === 0) return null;
  const ratings = userDiaryEntries
  .map(entry => entry.userRating)
  .filter(rating => rating !== null && rating !== 0); // Dodano warunek, aby wykluczyć oceny 0  if (ratings.length === 0) return null;
  const totalRating = ratings.reduce((acc, rating) => acc + rating, 0);
  return (totalRating / ratings.length).toFixed(1);
};

  if (loading) {
    return <div className="loader-container"><div className="loader"></div></div>    ;
  }

  if (!show) {
    return <div>Show not found</div>;
  }

  // Sprawdzenie, czy show jest zweryfikowany
  if (!show.isVerified) {
    return (
      <div className="container my-5 px-5 text-center">
        <h2 className="text-center pb-4">{t('showpage.not-verified_title')}</h2>
        <p>{t('showpage.not-verified_description')}</p>
      </div>
    );
  }

  const userRating = getUserRatingForShow();
  const ratingDescription = userRating ? getRatingDescription(userRating) : null;


  const maxLength = 440;
  

  const renderParagraphs = (text) => {
    return (
      <p
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };
  
  
  
  

  const shortDescription = show.about ? show.about.slice(0, maxLength) : '';

  const handleShowClick = (entry) => {
    setSelectedShow(entry);
    setSelectedEntry(entry); // <-- Set selectedEntry instead of selectedShow

  };
  
  const handleCloseModal = () => {
    setSelectedShow(null);
    setSelectedEntry(null); // Reset selectedEntry when modal closes

  };
  

  const pastShowsCount = userDiaryEntries.filter(entry => new Date(entry.dateWatched) < new Date()).length;
  const upcomingShowsCount = userDiaryEntries.filter(entry => new Date(entry.dateWatched) >= new Date(new Date().setHours(0, 0, 0, 0))).length;

  const handleAddToDiaryClick = () => {
    if (show && show.slug) {
      navigate(`/addToDiary?slug=${show.slug}`);
    } else {
      console.error('Slug is missing or invalid:', show);
    }
  };

  return (
    <Container fluid className="show-page px-3 justify-content-center">
      {/* Nagłówek z tłem */}
      <Row className="no-margin-row pos-relative">
    <Col xs={12} className="p-0">
      <div className="master-show-header">
        <div className="background-color-overlay"></div> {/* Overlay dla tła */}
        <div className="image-wrapper">
        {show.background && (
  <img 
    src={show.background.includes('http') ? show.background : `${process.env.REACT_APP_API_BASE_URL}/img${show.background}`}
    alt={show.title || 'No title'} 
    className="img-fluid master-show-background" 
  />
)}
          <div className="image-gradient"></div> {/* Gradient overlay */}
        </div>
      </div>
    </Col>


  <Col className="d-flex master-show-card-desktop mx-0 justify-content-center col-lg-11 col-xl-10 resp-pre-xl">
    <div className="master-show-poster">
      <img src={show.poster && show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`} className="poster-img" alt={show.title || 'No title'} />
    </div>
    <div className="show-info flex-grow-1">
      {show.globalRank > 0 && (
      <div className="ranking">
        <img src={rankIcon} alt="Rating" className="rank-icon" />
        <Link to={`/best-played`}>
          <span>#{show.globalRank || 'N/A'} {t('showpage.rank-global')}</span>
        </Link>
        <span>&nbsp;·&nbsp;</span>
        <Link to={`/best-played?country=${show.country}`}>
          <span>#{show.nationalRank || 'N/A'} {show.country}</span>
        </Link>
        <span>&nbsp;·&nbsp;</span>
        {show.location && (
<>
{show.location.split(', ').map((location, index) => (
  <>
    {index > 0 && <span>,&nbsp;</span>}
    <Link key={index} to={`/best-played?country=${show.country}&city=${location}`}>
      <span>#{locationRanks[location] || 'N/A'} {location}</span>
    </Link>
  </>
))}
        </>
        )}
      </div>
      )}

      <div className="show-info-basic">
        <h5 className="show-title mb-2">{show.title || 'No title'}</h5>

        {/* Sekcja teatru - wyświetlana tylko, gdy istnieje nazwa teatru */}
        {show.theater && (
          <div className="theater-info">{show.theater}</div>
        )}

        {/* Sekcja lokalizacji - wyświetlana tylko, gdy istnieje lokacja */}
        {show.location && (
          <div className="location">
<>
        {show.location.includes(',') ? (
          // Jeśli jest przecinek, tworzymy wiele linków
          show.location.split(', ').map((location, index) => (
            <>
              {index > 0 && <span>,&nbsp;</span>}
              
              <Link key={index} to={`/best-played?country=${show.country}&city=${location}`}>
                <span>{location}</span>
              </Link>
            
            </>
          ))
        ) : (
          // Jeśli nie ma przecinka, pojedynczy link
          
          <Link to={`/best-played?country=${show.country}&city=${show.location}`}>
            <span>{show.location}</span>
          </Link>
          
        )}
</>
</div>
)}
      </div>

      {show.avgRating > 0 && (
        <div className="rating">
          <img src={starIcon} alt="Rating" className="rating-star" />
          <span>{(show.avgRating || 0).toFixed(1)}</span>
        </div>
      )}

      {/* Sekcja tagów - wyświetlana tylko, gdy istnieją tagi */}
      {((show.tags && show.tags.length > 0) || (awards && awards.length > 0)) && (
        <div>
          <div className="tags">
          {show.tags.map((tag, index) => (
            <span key={index} className="tag">{tag}</span>
          ))}
          </div>
          {awards.length > 0 && (
  <div className="tags">
    <span onClick={handleOpenAwardsModal} className="tag-rewards cursor-pointer">
    {awards.some(award => award.categoryType === 'best-show') ? (
        <>
          {awards.length === 1 && awards[0].categoryType === 'best-show' ? (
                        t('showpage.awards.best-show')
          ) : (
            `${t('showpage.awards.best-show')} ${t('global.and')} ${awards.length - 1} ${
              awards.length - 1 === 1 ? t('showpage.awards.1-other-award') : 
              awards.length - 1 >= 2 && awards.length <= 4 ? t('showpage.awards.2-other-award') : 
              t('showpage.awards.5-other-award')
            }`
          )}
        </>
      ) : (
        `${awards.length} ${
          awards.length === 1 ? t('showpage.awards.1-award') :
          awards.length >= 2 && awards.length <= 4 ? t('showpage.awards.2-award') :
          t('showpage.awards.5-award')
        }`
      )}
    </span>
  </div>
)}
        </div>
      )}
      
    </div>
      {/* Sekcja z przyciskami */}
      <div className="master-additional-desktop">
        

          {/* Link do zakupu biletów */}
          {show.ticketUrl && (
            <a href={show.ticketUrl} target="_blank" rel="noopener noreferrer">
              <Button variant="primary" className="">{t('showpage.get-tickets-button')}</Button>
            </a>
          )}
        
      </div>
  </Col>
  </Row>

{/* Karta spektaklu z podstawowymi informacjami - MOBILE */}
<Row className="master-show-card mx-0 justify-content-center col-lg-11 col-xl-10 resp-pre-xl">
  <Col className="d-flex">
    <div className="master-show-poster">
    <img src={show.poster && show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`} className="poster-img" alt={show.title || 'No title'} />    </div>
    <div className="show-info flex-grow-1">
      {show.globalRank > 0 && (
      <div className="ranking">
        <img src={rankIcon} alt="Rating" className="rank-icon" />
        <Link to={`/best-played`}>
          <span>#{show.globalRank || 'N/A'} {t('showpage.rank-global')}</span>
        </Link>
        <span>&nbsp;·&nbsp;</span>
        <Link to={`/best-played?country=${show.country}`}>
          <span>#{show.nationalRank || 'N/A'} {show.country}</span>
        </Link>
        <span>&nbsp;·&nbsp;</span>
        {show.location && (
<>
{show.location.split(', ').map((location, index) => (
  <>
    {index > 0 && <span>,&nbsp;</span>}
    <Link key={index} to={`/best-played?country=${show.country}&city=${location}`}>
      <span>#{locationRanks[location] || 'N/A'} {location}</span>
    </Link>
  </>
))}
        </>
        )}
      </div>
      )}

      <div className="show-info-basic">
        <h5 className="show-title">{show.title || 'No title'}</h5>

        {/* Sekcja teatru - wyświetlana tylko, gdy istnieje nazwa teatru */}
        {show.theater && (
          <div className="theater-info">{show.theater}</div>
        )}

        {/* Sekcja lokalizacji - wyświetlana tylko, gdy istnieje lokacja */}
        {show.location && (
          <div className="location">
<>
        {show.location.includes(',') ? (
          // Jeśli jest przecinek, tworzymy wiele linków
          show.location.split(', ').map((location, index) => (
            <>
              {index > 0 && <span>,&nbsp;</span>}
              
              <Link key={index} to={`/best-played?country=${show.country}&city=${location}`}>
                <span>{location}</span>
              </Link>
            
            </>
          ))
        ) : (
          // Jeśli nie ma przecinka, pojedynczy link
          
          <Link to={`/best-played?country=${show.country}&city=${show.location}`}>
            <span>{show.location}</span>
          </Link>
          
        )}
</>
</div>
)}


      </div>

      {show.avgRating > 0 && (
        <div className="rating">
          <img src={starIcon} alt="Rating" className="rating-star" />
          <span>{(show.avgRating || 0).toFixed(1)}</span>
        </div>
      )}

      {/* Sekcja tagów - wyświetlana tylko, gdy istnieją tagi */}
      {show.tags && show.tags.length > 0 && (
        <div className="tags">
          {show.tags.map((tag, index) => (
            <span key={index} className="tag">{tag}</span>
          ))}
        </div>
      )}

      
    
    </div>
  </Col>
  <Col>
  {( (awards && awards.length > 0)) && (
        <div className="tag-rewards-mobile">
          {awards.length > 0 && (
  <div className="tags">
    <span onClick={handleOpenAwardsModal} className="tag-rewards cursor-pointer">
      {awards.some(award => award.categoryType === 'best-show') ? (
        <>
          {awards.length === 1 && awards[0].categoryType === 'best-show' ? (
                        t('showpage.awards.best-show')
          ) : (
            `${t('showpage.awards.best-show')} ${t('global.and')} ${awards.length - 1} ${
              awards.length - 1 === 1 ? t('showpage.awards.1-other-award') : 
              awards.length - 1 >= 2 && awards.length <= 4 ? t('showpage.awards.2-other-award') : 
              t('showpage.awards.5-other-award')
            }`
          )}
        </>
      ) : (
        `${awards.length} ${
          awards.length === 1 ? t('showpage.awards.1-award') :
          awards.length >= 2 && awards.length <= 4 ? t('showpage.awards.2-award') :
          t('showpage.awards.5-award')
        }`
      )}
    </span>
  </div>
)}
        </div>
      )}
  </Col>
</Row>

      {/* Sekcja z przyciskami */}
      <Row className="no-relative-position showpage-buttons no-show-mobile mb-2 g-0 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">

          {/* Link do zakupu biletów */}
          {show.ticketUrl && (
            <a href={show.ticketUrl} target="_blank" rel="noopener noreferrer">
              <Button variant="primary" className="get-tickets mb-3">{t('showpage.get-tickets-button')}</Button>
            </a>
          )}
        </Col>
      </Row>
    
      {user && (
<>
      {/* Sekcja z oceną użytkownika */}
      <Row className="my-rating mt-4 g-0 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl padding-12">
          <h2 className="section-title">{t('showpage.my_rating.title')}</h2>

          <div className="rating-top-section d-flex align-items-center justify-content-between">
            {userDiaryEntries.length > 0 ? (
              <div className="rating-top-section-text">
                <h3 className="section-subtitle">{t('showpage.my_rating.subtitle-summary')}</h3>
                <p className="rating-summary-p">                  
                  {t('showpage.my_rating.your-average-rating')}:&nbsp; 
                  <strong>
                    {userRating < 1 
                      ? t('modals.add_show_modal.no_rating') 
                      : ratingDescription || t('modals.add_show_modal.nothing')
                    } {!isNaN(userRating) && `(${
                      userRating < 1 
                        ? t('modals.add_show_modal.nothing') 
                        : userRating || 'N/A'
                    })`}
                  </strong>
                </p>
                <p className="rating-summary-p">
                {t('showpage.my_rating.you-have-seen')}: <strong>
                    {pastShowsCount} {pastShowsCount !== 1 ? t('showpage.my_rating.seen-more') : t('showpage.my_rating.seen-1')}
                    {upcomingShowsCount > 0 && ` (${upcomingShowsCount} ${t('showpage.my_rating.upcoming')})`}
                  </strong>
                </p>
              </div>
            ) : (
              <div className="rating-top-section-none">
                <p>{t('showpage.my_rating.you-have-not-seen')}</p>
              </div>
            )}
          
          <div>
          <Link to="/stats-rank">
            <img 
              src={user.avatarUrl.includes('http') ? user.avatarUrl : `${process.env.REACT_APP_API_BASE_URL}/img${user.avatarUrl}`} 
              alt="User Avatar" 
              className="user-avatar" 
            />
            </Link>
          </div>
        </div>
          <div>
          {userDiaryEntries.length > 0 ? (
              renderStarRating(userRating)
            ) : (
              <span></span>
            )}
          </div>
        </Col>
      </Row>

      {/* Sekcja z obejrzanymi spektaklami */}
      {userDiaryEntries.length > 0 && (
        <Row className="shows-seen mt-4 mb-0 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
            <h3 className="section-subtitle">{t('showpage.my_rating.subtitle-showsseen')}</h3>
            <div className="shows-grid">
              {userDiaryEntries.sort((a, b) => {
                // Jeśli oba wpisy nie mają daty lub mają datę null/undefined
                if ((!a.dateWatched || a.dateWatched === null) && (!b.dateWatched || b.dateWatched === null)) return 0;
                // Wpisy bez daty zawsze na końcu
                if (!a.dateWatched || a.dateWatched === null) return 1;
                if (!b.dateWatched || b.dateWatched === null) return -1;
                // Normalne sortowanie po dacie
                return new Date(b.dateWatched) - new Date(a.dateWatched);
              }).map((entry, index) => (
                <div 
                  key={index} 
                  className="show-card" 
                  onClick={() => handleShowClick(entry)}
                >
                  <div className="show-poster">
                    <img 
                      src={entry.show.poster.includes('http') ? entry.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                      alt={entry.show.title} 
                    />
                  </div>
                  {entry.userRating ? (
                    <div className="user-rating-badge">
                      <span>{entry.userRating}</span>
                    </div>
                  ) : (
                    <div className="user-rating-badge-star">
                      <img src={starIcon} alt="Rating" className="rating-star-badge" />
                    </div>
                  )}
                  
                  <div className="show-date">
                    <p>
                      <img src={calendarIcon} alt="Calendar" className="calendar-icon" />                       {(!entry.dateWatched || entry.dateWatched === null) ? 
                        t('common.no_date') : 
                        new Date(entry.dateWatched).toLocaleDateString()}
                    </p>
                    {new Date(entry.dateWatched) > new Date() ? 
                      <p className="upcoming">{t('showpage.my_rating.upcoming')}</p> : 
                      ''}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      )}

    

<Row className="mb-5 showpage-buttons showsseen-buttons g-0 justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
    <div className='center-container'>
      {userDiaryEntries.length > 0 ? (
        <Button variant="light" onClick={handleAddToDiaryClick} className="mt-3">{t('showpage.my_rating.add-show-button')}</Button>
      ) : (
        <Button variant="primary" onClick={handleAddToDiaryClick} className="mt-3">{t('showpage.my_rating.add-first-show-button')}</Button>
      )}
      {isInWishList ? (
        <Button 
          variant="danger" 
          className="remove-button" 
          onClick={handleRemoveFromWishList}
          disabled={isWishlistButtonLoading}
        >
          {isWishlistButtonLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            t('showpage.my_rating.remove-wish-button')
          )}
        </Button>
      ) : (
        <Button 
          variant="light" 
          className="" 
          onClick={handleAddToWishList}
          disabled={isWishlistButtonLoading}
        >
          {isWishlistButtonLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            t('showpage.my_rating.add-wish-button')
          )}
        </Button>
      )}
    </div>
  </Col>
</Row>
</>
)}

{/* Koniec sekcji zalogowanego użytkownika */}

{/* Sekcja niezalogowanego użytkownika */}

{!user && (
<>
      {/* Sekcja z oceną użytkownika */}
      <Row className="my-rating mt-4 g-0 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl padding-12">
          <h2 className="section-title">{t('showpage.my_rating.title')}</h2>

          <div className="rating-top-section d-flex align-items-center justify-content-between">
            
              <div className="rating-top-section-text">
                <h3 className="section-subtitle">{t('showpage.my_rating.subtitle-summary')}</h3>
                <p className="rating-summary-p">                  
                  {t('showpage.my_rating.your-average-rating')}:&nbsp; 
                  <strong>
                  {t('modals.add_show_modal.no_rating')}
                  </strong>
                </p>
              </div>
          
            
          
          <div>
          <Link to="/signup">
            <img 
              src={defaultPoster} 
              alt="User Avatar" 
              className="user-avatar" 
            />
            </Link>
          </div>
        </div>
          <div>
          <Link to="/signup">
              {renderStarRating(0)}
              </Link>
     
          </div>
        </Col>
      </Row>

    

<Row className="mb-5 mt-4 showpage-buttons showsseen-buttons g-0 justify-content-center">
<div className='center-container'>


<Link to="/signup">
<Button variant="primary" className="mt-3">{t('showpage.my_rating.add-first-show-button')}</Button>
</Link>
  <Link to="/signup">
  <Button 
    variant="light" 
    className="" 
    disabled={isWishlistButtonLoading}
  >
      {t('showpage.my_rating.add-wish-button')}
  </Button>
  </Link>
          </div>
</Row>
</>
)}

{/* Koniec sekcji niezalogowanego użytkownika */}

{calendarDates.length > 0 && (
  <>
    <Row className="show-dates-section mt-4 justify-content-center">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <h2 className="section-title">{t('showpage.calendar.title')}</h2>
        {Object.keys(groupDatesByMonth(calendarDates)).length > 0 ? (
          <div className="show-dates">
            {Object.entries(groupDatesByMonth(calendarDates))
              .slice(0, showAllMonths ? undefined : 1)
              .map(([month, dates]) => (
                <div key={`month-${month}`} className="month-dates">
                  <h3 className="month-name">
                    {format(dates[0].date, 'LLLL yyyy', { locale: currentLocale })}
                  </h3>
                  <div className="dates-list">
                    {dates
                      .sort((a, b) => a.date - b.date)
                      .map((dateInfo, index) => {
                        const dateKey = `${month}-${format(dateInfo.date, 'yyyy-MM-dd')}-${index}`;
                        return (
                          <div key={dateKey} className="date-entry">
                            <span className="date-weekday">
                              {format(dateInfo.date, 'EEEE', { locale: currentLocale })}
                            </span>
                            <span className="date-day">
                              {format(dateInfo.date, 'd.MM', { locale: currentLocale })}
                            </span>
                            <span className="date-location">
                              {dateInfo.altLocation || dateInfo.defaultLocation}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="mt-2 mb-4">
            <p>{t('showpage.calendar.no-shows')}</p>
          </div>
        )}
      </Col>
    </Row>

    <Row className="showpage-buttons showsseen-buttons nmt-2">
      <div className="center-container">
        {Object.keys(groupDatesByMonth(calendarDates)).length > 1 && !showAllMonths && (
          <Button 
            variant="light" 
            onClick={() => setShowAllMonths(true)}
          >
            {t('showpage.calendar.show-more-dates')}
          </Button>
        )}
        <Button 
          variant="light" 
          onClick={() => handleNavigation(`/calendar`)}
        >
          {t('showpage.calendar.go-to-calendar')}
        </Button>
      </div>
    </Row>
  </>
)}

{show.about && (
  <>
 {/* Sekcja opisująca spektakl */}
 <Row className="about mt-4 justify-content-center">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <h2 className="section-title">{t('showpage.about.title-about')}</h2>
        <div className="about-text">
            {isExpanded ? renderParagraphs(show.about) : renderParagraphs(shortDescription)}
          </div>
<div className="center-container">
          {!isExpanded && show.about && show.about.length > maxLength && (
            <Button variant="light" className="add-show-button mt-3" onClick={() => setIsExpanded(true)}>
            {t('showpage.about.read-more-button')}
          </Button>          )}

        {isExpanded && (
          <Button variant="light" className="add-show-button mt-3" onClick={() => setIsExpanded(false)}>
            {t('showpage.about.less-info-button')}
        </Button>
        )}
        </div>
      </Col>
    </Row>
    </>
)}
{/* Sekcja z dodatkowymi informacjami */}
{(show.duration || show.direction || show.author || show.musicAuthor || show.translation || show.premiere) && (
  <Row className="information mt-4 justify-content-center">
    <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
      <h2 className="section-title">{t('showpage.about.title-info')}</h2>

      <div className="info-table">
        {show.duration && (
          <div className="info-row">
            <div className="info-icon">
              <img src={clockIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-duration')}</div>
            <div className="info-content">{show.duration} min</div>
          </div>
        )}

        {show.direction && (
          <div className="info-row">
            <div className="info-icon">
              <img src={userIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-direction')}</div>
            <div className="info-content">{show.direction}</div>
          </div>
        )}

        {show.author && (
          <div className="info-row">
            <div className="info-icon">
              <img src={fileIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-author')}</div>
            <div className="info-content">{show.author}</div>
          </div>
        )}

        {show.musicAuthor && (
          <div className="info-row">
            <div className="info-icon">
              <img src={musicIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-music')}</div>
            <div className="info-content">{show.musicAuthor}</div>
          </div>
        )}

        {show.translation && (
          <div className="info-row">
            <div className="info-icon">
              <img src={messageIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-translation')}</div>
            <div className="info-content">{show.translation}</div>
          </div>
        )}

        {show.premiere && (
          <div className="info-row">
            <div className="info-icon">
              <img src={calendarIcon} alt="Icon" />
            </div>
            <div className="info-name">{t('showpage.about.info-premiere')}</div>
            <div className="info-content">{formatDate(show.premiere)}</div>
          </div>
        )}
      </div>
    </Col>
  </Row>
)}


      {/* Sekcja obsady */}

      <Row className="cast-section-row mt-4 justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
    {show.cast && show.cast.length > 0 && (
      <>
        <h2 className="section-title">{t('showpage.about.title-cast')}</h2>
        <div className="cast-section">
        {show.cast.sort((a, b) => a.order - b.order).map((castMember, index) => (
          <Link to={`/person/${castMember.actor.slug}`}>
          <div className="cast-member" key={index}>
            <img 
              src={castMember.actor.photoUrl && castMember.actor.photoUrl.includes('http')  ? castMember.actor.photoUrl  : `${process.env.REACT_APP_API_BASE_URL}/img${castMember.actor.photoUrl}`}
              alt={`${castMember.actor.firstName} ${castMember.actor.lastName}`} 
              className="cast-photo"
            />
            <p className="cast-name">{castMember.actor.firstName} {castMember.actor.lastName}</p>
          </div>
          </Link>
        ))}
      </div>
      </>
    )}
  </Col>
</Row>

<ShowModal
  starIcon={starIcon}
  colorCircleCalendarIcon={colorCircleCalendarIcon}
  colorCircleStarIcon={colorCircleStarIcon}
  getRatingDescription={getRatingDescription}
  renderStarRating={renderStarRating}
  show={selectedShow}
  onClose={handleCloseModal}
  t={t}
  currentLocale={currentLocale}
  successIcon={successIcon}
  noteIcon={noteIcon}
  selectedEntry={selectedEntry} // <-- Pass selectedEntry to ShowModal

/>

      {/* Modal obserwowanych */}
      <Modal show={isAwardsModalOpen} onHide={handleCloseAwardsModal} className="modal-calendar-container modal-awards-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-calendar modal-followers">
            <h1 className="mb-4">{t('showpage.awards.selected-awards')}</h1>

            
              {awards.map((award, index) => (
    <Card key={index}>
      <Card.Body className="users-list justify-content-between align-items-center">
        <div className="users-list-main d-flex justify-content-between align-items-center">
          <div className="users-list-left align-items-center">
            <img 
              src={award.name.includes('Kiepury') ? awardsKiepuryIcon : awardsPlebiscytIcon} 
              alt={award.name} 
              className={`user-avatar ${award.name.includes('Kiepury') ? 'no-border-radius' : ''}`} 
            />
            <div className="awards-modal">
              <span className="award-type">
                {award.isEditionPrefix ? `${award.edition} ${award.name}` : `${award.name} ${award.edition}`}
              </span>
              <span className="award-title">
                {award.categoryName}
              </span>
              <span className="award-details">
                {[...new Set(award.winners
                  .filter(winner => 
                    // Filtrujemy tylko zwycięzców związanych z tym spektaklem
                    winner.show && winner.show._id === show._id
                  )
                  .map(winner => 
                    winner.creative ? `${winner.creative.firstName} ${winner.creative.lastName}` : 
                    winner.cast ? `${winner.cast.firstName} ${winner.cast.lastName}` : 
                    winner.show ? winner.show.title : ''
                  ))].join(', ')}
              </span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  ))}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseAwardsModal}>{t('modals.close_button')}</Button>
        </Modal.Footer>
      </Modal>



    </Container>
  );
};

export default ShowPage;