import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Modal, Card, Button, Container, Row, Col } from 'react-bootstrap';

import awardsPlebiscytIcon from './img/awards-plebiscyt.jpg';
import awardsKiepuryIcon from './img/awards-kiepury.jpg';

const PersonPage = ({ t, starIcon, rankIcon, user, isLoading, getUserRatingForShow }) => {
  const { slug } = useParams();
  const [actor, setActor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAwardsModalOpen, setIsAwardsModalOpen] = useState(false); 

  const handleOpenAwardsModal = () => {
    setIsAwardsModalOpen(true);
  };

  const handleCloseAwardsModal = () => {
    setIsAwardsModalOpen(false);
  };

  useEffect(() => {
    const fetchActor = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/cast/${slug}`);
        setActor(response.data);
        // Teraz response.data zawiera również tablicę awards
        // Możesz użyć response.data.awards aby uzyskać dostęp do nagród
      } catch (error) {
        console.error('Error fetching the actor:', error);
        setActor(null);
      } finally {
        setLoading(false);
      }
    };
  
    fetchActor();
  }, [slug]);

  if (loading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  if (!actor) {
    return <div>Actor not found</div>;
  }

  return (
    <Container fluid className="person-page px-3 justify-content-center">
      
      {/* Nagłówek z tłem */}
      <Row className="no-margin-row pos-relative mb-3">
        <Col xs={12} className="p-0">
          <div className="master-show-header">
            <div className="background-color-overlay"></div>
            <div className="image-wrapper">
              <div className="image-gradient"></div>
            </div>
          </div>
        </Col>

        <Col className="d-flex master-show-card-desktop mx-0 justify-content-center col-lg-11 col-xl-10 resp-pre-xl">
          <div className="master-show-poster">
            <img 
              src={actor.photoUrl && actor.photoUrl.includes('http') ? actor.photoUrl : `${process.env.REACT_APP_API_BASE_URL}/img${actor.photoUrl}`} 
              className="poster-img" 
              alt={actor.lastName || 'No name'} 
            /> 
          </div>
          <div className="show-info2 flex-grow-1">
            <div className="show-info-basic">
              <h5 className="person-name">{actor.firstName || ''} {actor.lastName || ''}</h5>
            </div>

      {/* Sekcja tagów - wyświetlana tylko, gdy istnieją tagi */}

          {actor.awards.length > 0 && (
  <div className="tags">
    <span onClick={handleOpenAwardsModal} className="tag-rewards cursor-pointer">
    {(
        `${actor.awards.length} ${
          actor.awards.length === 1 ? t('showpage.awards.1-award') :
          actor.awards.length >= 2 && actor.awards.length <= 4 ? t('showpage.awards.2-award') :
          t('showpage.awards.5-award')
        }`
      )}
    </span>
  </div>
)}

          </div>
        </Col>
      </Row>

      {/* Karta spektaklu z podstawowymi informacjami - MOBILE */}
      <Row className="master-show-card mx-0 justify-content-center col-lg-11 col-xl-10 resp-pre-xl">
        <Col className="d-flex">
          <div className="master-show-poster">
            <img 
              src={actor.photoUrl && actor.photoUrl.includes('http') ? actor.photoUrl : `${process.env.REACT_APP_API_BASE_URL}/img${actor.photoUrl}`} 
              className="poster-img" 
              alt={actor.lastName || 'No name'} 
            />
          </div>
          <div className="show-info2 flex-grow-1">
            <div className="show-info-basic">
              <h5 className="person-name">{actor.firstName || ''} {actor.lastName || ''}</h5>
            </div>

            {actor.awards.length > 0 && (
  <div className="tags">
    <span onClick={handleOpenAwardsModal} className="tag-rewards cursor-pointer">
    {(
        `${actor.awards.length} ${
          actor.awards.length === 1 ? t('showpage.awards.1-award') :
          actor.awards.length >= 2 && actor.awards.length <= 4 ? t('showpage.awards.2-award') :
          t('showpage.awards.5-award')
        }`
      )}
    </span>
  </div>
)}
          </div>
        </Col>
      </Row>

      {!isLoading && actor.shows && actor.shows.length > 0 && (
        <>
          <Row className="g-0 best-played mb-5 justify-content-center">
            <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl relative-reset">
              <h2 className="section-title mt-2 mb-3">Wybrane powiązane spektakle:</h2>
            </Col>
            
            <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
              {/* Wyświetlanie posortowanych spektakli */}
              {actor.shows.map((show, index) => (
                <div key={show._id} className="show-card">
                  <div className="show-poster">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <img
                        src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                        alt={show.title}
                        className="poster-img"
                      />
                    </Link>
                    {user && getUserRatingForShow(show._id) ? (
                      <div className="user-rating-badge">
                        <span>{getUserRatingForShow(show._id)}</span>
                      </div>
                    ) : (
                      <div className="user-rating-badge-star">
                        <img src={starIcon} alt="Rating" className="rating-star-badge" />
                      </div>
                    )}
                  </div>

                  <div className="show-info">
                    <div className="show-info-basic">
                      <Link to={`/shows/${show.slug}`} className="show-link">
                        <h5 className="show-title">{show.title}</h5>
                      </Link>
                      <div className="theater-info">{show.theater}</div>
                      <div className="location">{show.location}</div>
                    </div>

                    {show.avgRating > 0 && (
                      <div className="rating">
                        <img src={starIcon} alt="Rating" className="rating-star" />
                        <span>{(show.avgRating || 0).toFixed(1)}</span>
                      </div>
                    )}
                    
                    <div className="tags">
                      {show.tags.map((tag, tagIndex) => (
                        <span key={tagIndex} className="tag">{tag}</span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}

           {/* Modal obserwowanych */}
           <Modal show={isAwardsModalOpen} onHide={handleCloseAwardsModal} className="modal-calendar-container modal-awards-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-calendar modal-followers">
            <h1 className="mb-4">{t('showpage.awards.selected-awards')}</h1>

            
              {actor.awards.map((award, index) => (
    <Card key={index}>
      <Card.Body className="users-list justify-content-between align-items-center">
        <div className="users-list-main d-flex justify-content-between align-items-center">
          <div className="users-list-left align-items-center">
            <img 
              src={award.name.includes('Kiepury') ? awardsKiepuryIcon : awardsPlebiscytIcon} 
              alt={award.name} 
              className={`user-avatar ${award.name.includes('Kiepury') ? 'no-border-radius' : ''}`} 
            />
            <div className="awards-modal">
              <span className="award-type">
                {award.isEditionPrefix ? `${award.edition} ${award.name}` : `${award.name} ${award.edition}`}
              </span>
              <span className="award-title">
                {award.categoryName}
              </span>
              <span className="award-details">
                {[...new Set(award.winners
                  .filter(winner => 
                    (winner.cast && winner.cast._id === actor._id) || 
                    (winner.creative && winner.creative._id === actor._id)
                  )
                  .map(winner => 
                    winner.creative ? `${winner.creative.firstName} ${winner.creative.lastName}` : 
                    winner.cast ? `${winner.cast.firstName} ${winner.cast.lastName}` : ''
                  ))].join(', ')}
              </span>
              <span className="award-details2">(
                {[...new Set(award.winners
                  .filter(winner => 
                    (winner.cast && winner.cast._id === actor._id) || 
                    (winner.creative && winner.creative._id === actor._id)
                  )
                  .map(winner => winner.show?.title)
                  .filter(Boolean))].join(', ')}
              )</span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  ))}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseAwardsModal}>{t('modals.close_button')}</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PersonPage;