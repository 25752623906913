import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Spinner, Card, Button } from 'react-bootstrap';
import axios from 'axios';

const UserPage = ({ 
  calendarIcon,
  starIcon, 
  colorCircleCalendarIcon,
  colorCircleStarIcon,
  getRatingDescription,
  renderStarRating,
  successIcon,
  currentLocale,
  isLoading,
  setIsLoading,
  chevronUpIcon,
  chevronDownIcon,
  eyeIcon,
  t,
  infoIcon
}) => {
  const { googleId } = useParams(); // Używamy googleId jako unikalnego identyfikatora użytkownika
  const [diaryEntries, setDiaryEntries] = useState([]);
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [isPublic, setIsPublic] = useState(false); // Stan dla isPublic
  const [isFollowing, setIsFollowing] = useState(false); // Stan dla śledzenia
  const [loading, setLoading] = useState(false); // State to track loading for follow/unfollow
  const [topRatedShows, setTopRatedShows] = useState([]); // Top oceniane spektakle
  const [topShowsByAttendance, setTopShowsByAttendance] = useState([]); // Najczęściej oglądane spektakle
  const [rankedShows, setRankedShows] = useState([]);
  const [dbRankedShows, setDbRankedShows] = useState([]);
  const [dbRankedShowsReady, setDbRankedShowsReady] = useState(false); // Nowy stan gotowości

  const [isDefaultRankedShows, setIsDefaultRankedShows] = useState(false); // Zmienna dla stanu rankingu


  const [expandedSections, setExpandedSections] = useState({
    topRated: true, // Domyślnie rozwinięte
    topAttendance: true, // Domyślnie rozwinięte
    fullDiary: true,
    ranked: true
  });
  const [visibleEntriesCount, setVisibleEntriesCount] = useState(18); // Początkowa liczba widocznych spektakli



  const loadMoreEntries = () => {
    setVisibleEntriesCount(prevCount => prevCount + 18); // Dodaj kolejne 18 wpisów
  };

   // Sortowanie wszystkich wpisów malejąco według daty
   const sortedEntries = diaryEntries.sort((a, b) => {
    // Jeśli oba wpisy nie mają daty lub mają datę null
    if ((!a.dateWatched || a.dateWatched === null) && (!b.dateWatched || b.dateWatched === null)) return 0;
    // Wpisy bez daty zawsze na końcu
    if (!a.dateWatched || a.dateWatched === null) return 1;
    if (!b.dateWatched || b.dateWatched === null) return -1;
    // Normalne sortowanie po dacie
    return new Date(b.dateWatched) - new Date(a.dateWatched);
  });

   const countryEmojis = {
    Polska: '🇵🇱', // Polska
    UK: '🇬🇧', // Wielka Brytania
    USA: '🇺🇸'  // Stany Zjednoczone
  };
  
// Funkcja do przetwarzania i ustawiania rankedShows
const processRankedShows = useCallback((entries) => {
  if (isDefaultRankedShows || !dbRankedShowsReady) return; // Sprawdź, czy dane są gotowe


  const uniqueShows = new Map();
  entries.forEach(entry => {
    if (entry && entry.show && entry.show._id) { // Dodane zabezpieczenie
      const showId = entry.show._id.toString();
      if (!uniqueShows.has(showId)) {
        uniqueShows.set(showId, entry.show);
      }
    }
  });

  const showOrderMap = new Map();
  dbRankedShows.forEach((showId, index) => {
    showOrderMap.set(showId.toString(), index);
  });

  // Sortujemy unikalne spektakle według kolejności z preferencji użytkownika
  const sortedByUserOrder = Array.from(uniqueShows.values())
    .filter(show => showOrderMap.has(show._id.toString()))
    .sort((a, b) => showOrderMap.get(a._id.toString()) - showOrderMap.get(b._id.toString()))
    .slice(0, 10);  // Pobieramy tylko pierwsze 10

  setRankedShows(sortedByUserOrder);
}, [isDefaultRankedShows, dbRankedShowsReady, dbRankedShows]);
  

// Pobieranie danych dziennika użytkownika po `googleId`
useEffect(() => {
  const fetchDiaryEntries = async () => {
    setIsLoading(true);
    console.log('Fetching diary entries for Google ID:', googleId); // Loguj Google ID przed fetchowaniem danych

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user/${googleId}/diary`);
      setDiaryEntries(response.data.diary);  // Ustaw dziennik
      setUserName(response.data.name);       // Ustaw nazwę użytkownika
      setUserAvatar(response.data.avatar);       // Ustaw nazwę użytkownika
      setIsPublic(response.data.isPublic);   // Ustaw status prywatności
      setIsDefaultRankedShows(response.data.isDefaultRankedShows);

                    // Oblicz topowe spektakle
                    calculateTopRatedShows(response.data.diary);
                    calculateTopShowsByAttendance(response.data.diary);

      setDbRankedShows(response.data.rankedShows); // Przypisz rankedShows do dbRankedShows
    } catch (error) {
      console.error('Error fetching user diary entries:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (googleId) {
    fetchDiaryEntries();
  }
}, [googleId, setIsLoading]);

// Monitorowanie zmiany wartości dbRankedShows i przetwarzanie rankedShows
useEffect(() => {
  if (dbRankedShows.length > 0 && !isDefaultRankedShows) {
    setDbRankedShowsReady(true); // Ustaw gotowość do przetwarzania
    processRankedShows(diaryEntries);



  }
}, [dbRankedShows, isDefaultRankedShows, diaryEntries, processRankedShows]);


  // Funkcja sprawdzania, czy użytkownik jest śledzony
  useEffect(() => {
    const fetchFollowingStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/following`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const isUserFollowed = response.data.some(user => user.googleId === googleId); // Sprawdzamy czy użytkownik jest śledzony
        setIsFollowing(isUserFollowed);
      } catch (error) {
        console.error('Error fetching following status:', error.response ? error.response.data : error.message);
      }
    };

    fetchFollowingStatus();
  }, [googleId]);

  const handleFollow = async () => {
    const token = localStorage.getItem('token');
    setLoading(true); // Activate loading
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/follow`,
        { followUserId: googleId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsFollowing(true);
    } catch (error) {
      console.error('Error following user:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false); // Deactivate loading
    }
  };

  const handleUnfollow = async () => {
    const token = localStorage.getItem('token');
    setLoading(true); // Activate loading
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/unfollow`,
        { unfollowGoogleId: googleId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsFollowing(false);
    } catch (error) {
      console.error('Error unfollowing user:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false); // Deactivate loading
    }
  };

  const followButton = (
    <div>
      {isFollowing ? (
        <Button
          variant="danger"
          onClick={handleUnfollow}
          disabled={loading} // Disable the button during loading
        >
          {loading ? <Spinner animation="border" size="sm" /> : t('my_account.unfollow')}
        </Button>
      ) : (
        <Button
          variant="success"
          onClick={handleFollow}
          disabled={loading} // Disable the button during loading
        >
          {loading ? <Spinner animation="border" size="sm" /> : t('my_account.follow')}
        </Button>
      )}
    </div>
  );

  // Memoizowanie diarySections
  const diarySections = useMemo(() => {
    const today = new Date();
    const nextShows = diaryEntries.filter(entry => new Date(entry.dateWatched) >= today)
                                  .sort((a, b) => new Date(a.dateWatched) - new Date(b.dateWatched));

    const pastShows = diaryEntries.filter(entry => new Date(entry.dateWatched) < today)
                                  .sort((a, b) => new Date(b.dateWatched) - new Date(a.dateWatched));

    const groupedByYear = pastShows.reduce((acc, entry) => {
      const year = new Date(entry.dateWatched).getFullYear();
      if (!acc[year]) acc[year] = [];
      acc[year].push(entry);
      return acc;
    }, {});

    const sections = [];

    if (nextShows.length > 0) {
      sections.push({ title: t('my_shows.tabs.next'), id: 'next', shows: nextShows });
    }

    Object.keys(groupedByYear).sort((a, b) => b - a).forEach(year => {
      sections.push({ title: year, id: year.toString(), shows: groupedByYear[year] });
    });

    return sections;
  }, [diaryEntries, t]);

  // Zaktualizowanie stanu rozwiniętych sekcji
  useEffect(() => {
    const expanded = { next: true };

    const pastSections = diarySections.filter(section => section.id !== 'next');
    if (pastSections.length > 0) {
      expanded[pastSections[0].id] = true;
    }
    if (pastSections.length > 1) {
      expanded[pastSections[1].id] = true;
    }

    setExpandedSections(prevExpanded => ({
      ...prevExpanded,
      ...expanded
    }));
  }, [diarySections]);

  // Funkcja do rozwijania/zawijania sekcji
  const toggleSection = (id) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };


  const calculateTopRatedShows = (entries) => {
    // Grupowanie wpisów według showId
    const showRatings = {};
  
    entries.forEach(entry => {
      const showId = entry.show._id;
      const rating = entry.userRating;
  
      if (rating !== null && rating !== 0) { // Dodano warunek wykluczający oceny 0
        if (!showRatings[showId]) {
          showRatings[showId] = { ratings: [], show: entry.show };
        }
        showRatings[showId].ratings.push(rating);
      }
    });
  
  // Obliczenie średniej dla każdego show
  const averageRatings = Object.values(showRatings).map(showData => {
    // Filtrujemy oceny, wykluczając null i 0
    const validRatings = showData.ratings.filter(rating => rating !== null && rating !== 0);
    
    // Sprawdzamy czy mamy jakieś ważne oceny
    const averageRating = validRatings.length > 0
      ? (validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length).toFixed(1)
      : 0;

    return {
      show: showData.show,
      averageRating: averageRating,
    };
    });
  
    const sortedShows = averageRatings.sort((a, b) => b.averageRating - a.averageRating);
    setTopRatedShows(sortedShows.slice(0, 9)); // Pobieramy top 5
  };
  
  const calculateTopShowsByAttendance = (entries) => {
    const attendanceCount = {};
  
    entries.forEach(entry => {
      const showId = entry.show._id;
      if (!attendanceCount[showId]) {
        attendanceCount[showId] = { count: 0, show: entry.show };
      }
      attendanceCount[showId].count += 1;
    });
  
    const sortedAttendance = Object.values(attendanceCount).sort((a, b) => b.count - a.count);
    setTopShowsByAttendance(sortedAttendance.slice(0, 9)); // Pobieramy top 5
  };
  



  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }


  if (!isPublic) {
    return (
      <Container fluid className="px-3 main-container my-account stats-rank">
        <Row className="diary my-shows mb-5 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          <h1 className="page-title">
           {t('my_account.user_title')}
          </h1>
          
          <div className="account-intro">
            <img
              src={userAvatar && userAvatar.includes('http')
                ? userAvatar
                : `${process.env.REACT_APP_API_BASE_URL}/img${userAvatar || ''}`}
              alt="User Avatar"
              className="user-avatar"
            />
            <h2 className="mb-1 mt-3">{userName.split(' ')[0]} {userName.split(' ')[1] ? userName.split(' ')[1][0] + '.' : ''}</h2>
            <div className="follow-button d-flex mt-4">
          {/* Wyświetlanie przycisku Follow/Unfollow */}
          {followButton}

          </div>
          </div>
          </Col>
        </Row>
        <Row>
          <div className="container my-5 px-5 text-center">
            <h2 className="text-center pb-4">{t('my_account.user_hidden_title')}</h2>
            <p>{t('my_account.user_hidden_subtitle')}</p>
          </div>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="px-3 main-container my-account stats-rank">
      <Row className="diary my-shows mb-4 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          <h1 className="page-title">
           {t('my_account.user_title')}
          </h1>
          
          <div className="account-intro">
            <img
              src={userAvatar && userAvatar.includes('http')
                ? userAvatar
                : `${process.env.REACT_APP_API_BASE_URL}/img${userAvatar || ''}`}
              alt="User Avatar"
              className="user-avatar"
            />
            <h2 className="mb-1 mt-3">{userName.split(' ')[0]} {userName.split(' ')[1] ? userName.split(' ')[1][0] + '.' : ''}</h2>
            <div className="follow-button d-flex mt-4 mb-3">
          {/* Wyświetlanie przycisku Follow/Unfollow */}
          {followButton}

          </div>
          </div>


</Col></Row>
      {/* Najlepiej Ocenione Sekcja */}
      <Row className="mb-2 justify-content-center diary">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">

        <div className="diary-section">
          <div 
              className={`diary-section-top ${expandedSections.topRated ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('topRated')}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <h2 className="section-title mb-0 pb-0">{t('my_account.user_top_rated')}</h2>
            <div className="arrow-icon">
                {expandedSections.topRated ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
          </div>
          <div className={`diary-section-grid ${expandedSections.topRated ? 'expanded' : 'collapsed'}`}   >

          {expandedSections.topRated && (
            <div className="shows-grid">
              {topRatedShows.length === 0 && (
              <div className="all-shows-info">
                <img src={infoIcon} alt="Info" className="info-icon" />
                {t('my_account.user_empty')}
              </div>
              )}
              {topRatedShows.map((show, index) => (
                <div key={index} className="show-card">
                  <Link to={`/shows/${show.show.slug}`} className="show-link">
                    <div className="show-poster">
                      <img 
                        src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`} 
                        alt={show.show.title} 
                      />
                    </div>
                  </Link>
                  <div className="show-info">
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    <span className="show-rating">  {show.averageRating}</span>   
                    <p className="show-rating-sub">{t('my_account.rating')}</p>   
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
          </div>
        </Col>
      </Row>

      {/* Najczęściej Oglądane Sekcja */}
      <Row className="mb-2 justify-content-center diary">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <div className="diary-section">
          <div 
              className={`diary-section-top ${expandedSections.topAttendance ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('topAttendance')}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <h2 className="section-title mb-0 pb-0">{t('my_account.user_top_attendance')}</h2>
            <div className="arrow-icon">
                {expandedSections.topAttendance ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
          </div>
          <div className={`diary-section-grid ${expandedSections.topAttendance ? 'expanded' : 'collapsed'}`}   >

          {expandedSections.topAttendance && (
            <div className="shows-grid">
              {topShowsByAttendance.length === 0 && (
              <div className="all-shows-info">
                <img src={infoIcon} alt="Info" className="info-icon" />
                {t('my_account.user_empty')}
              </div>
              )}
              {topShowsByAttendance.map((show, index) => (
                <div key={index} className="show-card">
                  <Link to={`/shows/${show.show.slug}`} className="show-link">
                    <div className="show-poster">
                      <img 
                        src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`} 
                        alt={show.show.title} 
                      />
                    </div>
                  </Link>
                  <div className="show-info">
                    <img src={eyeIcon} alt="Count" className="seen-eye" />
                    <span className="show-rating">                    
                      {show.count} {show.count > 1 ? t('showpage.my_rating.seen-more') : t('showpage.my_rating.seen-1')}
                    </span>   
                    <p className="show-rating-sub">{t('my_account.entries')}</p>   
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
          </div>
        </Col>
      </Row>

{!isDefaultRankedShows && (
<>
{/* Mój prywatny ranking */}
<Row className="mb-2 justify-content-center stats-rank diary">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
    <div className="diary-section">
      <div 
        className={`diary-section-top ${expandedSections.ranked ? 'expanded' : 'collapsed'}`} 
        onClick={() => toggleSection('ranked')}
        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h2 className="section-title mb-0 pb-0">{t('my_account.user_rank')}</h2>
        <div className="arrow-icon">
          {expandedSections.ranked ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
        </div>
      </div>

      <div className={`diary-section-grid ${expandedSections.ranked ? 'expanded' : 'collapsed'}`}>
        {expandedSections.ranked && rankedShows && rankedShows.length > 0 && (
          <div className="priv-rank-container">
            {rankedShows.map((show, index) => (
              <Card key={show._id} className="mb-3">
                <Card.Body className="d-flex align-items-start">
                  <div className="rank-item d-flex">
                    <div className="rank-number">
                      {index + 1}
                    </div>
                    <div className="show-poster">
                      {show.poster && (
                        <img
                          src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                          alt={show.title}
                          className="poster-img"
                        />
                      )}
                    </div>
                    <div className="rank-title-theater flex-grow-1">
                      <Card.Title className="show-title my-1 text-wrap">{show.title}</Card.Title>
                      {show.theater && (
                        <Card.Subtitle className="theater-info my-1 text-wrap">{show.theater}</Card.Subtitle>
                      )}
                      {show.location && (
                        <div className="location my-1">
                          {countryEmojis[show.country] && (
                            <span>{countryEmojis[show.country]} </span>
                          )}
                          {show.location}
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  </Col>
</Row>

</>
)}

      {/* full diary */}
      <Row className="mb-2 justify-content-center diary">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <div className="diary-section">
          <div 
              className={`diary-section-top ${expandedSections.fullDiary ? 'expanded' : 'collapsed'}`} 
              onClick={() => toggleSection('fullDiary')}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <h2 className="section-title mb-0 pb-0">{t('my_account.user_full_diary')}</h2>
            <div className="arrow-icon">
                {expandedSections.fullDiary ? <img src={chevronUpIcon} alt="Collapse" className="quick-icon" /> : <img src={chevronDownIcon} alt="Expand" className="quick-icon expand" />}
              </div>
          </div>
          <div className={`diary-section-grid ${expandedSections.fullDiary ? 'expanded' : 'collapsed'}`}   >

          {expandedSections.fullDiary && (
            <div className="shows-grid">
                          {sortedEntries.length === 0 && (
              <div className="all-shows-info">
                <img src={infoIcon} alt="Info" className="info-icon" />
                {t('my_account.user_empty')}
              </div>
              )}
            {sortedEntries.slice(0, visibleEntriesCount).map((show, index) => (
                <div key={index} className="show-card">
                  <Link to={`/shows/${show.show.slug}`} className="show-link">
                    <div className="show-poster">
                      <img 
                        src={show.show.poster.includes('http') ? show.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`} 
                        alt={show.show.title} 
                      />
                    </div>
                  </Link>
                  <div className="show-info">
                    
                    {show.userRating > 0 ? (
                      <>
                      <img src={starIcon} alt="Rating" className="rating-star" />
                    <span className="show-rating">{show.userRating}</span>   
                    </>
                    ) : (
                      <>
                      <img src={starIcon} alt="Rating" className="rating-star grey" />
                      <span className="show-rating"></span>   
</>
                    )}
                    
                    <p className="show-rating-sub">
                    {(!show.dateWatched || show.dateWatched === null) 
                      ? t('common.no_date') 
                      : new Date(show.dateWatched).toLocaleDateString()
                      }
                    </p>   
                  </div>
                  
                </div>
              ))}

            </div>
            

            
          )}
          
                                            {/* Przycisk "show more" */}
                                            {visibleEntriesCount < sortedEntries.length && (
            <div className="text-center mt-3 mb-4 show-more-entries">
              <Button onClick={loadMoreEntries}>{t('buttons.show_more')}</Button>
            </div>
          )}

          </div>

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPage;