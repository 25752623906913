import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const isFemaleFirstName = (name) => {
  if (!name) return false;
  const firstName = name.split(' ')[0]; // Bierzemy pierwszy człon
  return firstName.endsWith('a') && 
         !['Kuba', 'Barnaba', 'Bonawentura'].includes(firstName); // Wyjątki męskich imion
};


const ActivityFeed = ({
  user,
  t,
  formatDate,
  calendarIcon,
  renderStarRating,
  infoIcon,
}) => {
  const [recentActivities, setRecentActivities] = useState([]);
  const [followingActivities, setFollowingActivities] = useState([]);
  const [displayedActivities, setDisplayedActivities] = useState([]);
  const [displayedFollowingActivities, setDisplayedFollowingActivities] = useState([]);
  const [isLoadingRecent, setIsLoadingRecent] = useState(true);
  const [isLoadingFollowing, setIsLoadingFollowing] = useState(true);
  const [page, setPage] = useState(1);
  const [followingPage, setFollowingPage] = useState(1);
  
  const ACTIVITIES_PER_PAGE = 5;
  const MAX_ACTIVITIES = 28;

  useEffect(() => {
    const fetchRecentActivities = async () => {
      setIsLoadingRecent(true);
      try {
        console.log('Fetching recent activities...');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/recent`, {
          params: { limit: 28 },
          headers: user ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {}
        });
        console.log('Received recent activities response:', response);
        console.log('Recent activities data:', response.data);
        setRecentActivities(response.data);
        setDisplayedActivities(response.data.slice(0, ACTIVITIES_PER_PAGE));
      } catch (error) {
        console.error('Error fetching recent activities:', error);
      } finally {
        setIsLoadingRecent(false);
      }
    };

    const fetchFollowingActivities = async () => {
      setIsLoadingFollowing(true);
      if (!user) {
        setIsLoadingFollowing(false);
        return;
      }

      try {
        console.log('Fetching following activities...');
        const followingResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/users/following-activities`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            params: { limit: 28 }
          }
        );
        console.log('Received following activities:', followingResponse.data);
        setFollowingActivities(followingResponse.data);
        setDisplayedFollowingActivities(followingResponse.data.slice(0, ACTIVITIES_PER_PAGE));
      } catch (error) {
        console.error('Error fetching following activities:', error);
      } finally {
        setIsLoadingFollowing(false);
      }
    };

    fetchRecentActivities();
    fetchFollowingActivities();
  }, [user]);

  const loadMoreActivities = () => {
    const nextPage = page + 1;
    const startIndex = 0;
    const endIndex = Math.min(nextPage * ACTIVITIES_PER_PAGE, MAX_ACTIVITIES);
    
    setDisplayedActivities(recentActivities.slice(startIndex, endIndex));
    setPage(nextPage);
  };

  const loadMoreFollowingActivities = () => {
    const nextPage = followingPage + 1;
    const startIndex = 0;
    const endIndex = Math.min(nextPage * ACTIVITIES_PER_PAGE, MAX_ACTIVITIES);
    
    setDisplayedFollowingActivities(followingActivities.slice(startIndex, endIndex));
    setFollowingPage(nextPage);
  };

  const hasMoreActivities = displayedActivities.length < Math.min(recentActivities.length, MAX_ACTIVITIES);
  const hasMoreFollowingActivities = displayedFollowingActivities.length < Math.min(followingActivities.length, MAX_ACTIVITIES);

  return (
    <Container fluid className="px-3 main-container feed-page">
      <Row className="g-0 justify-content-center feed-page-row">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          <h1 className="page-title">{t('activities.homepage-title')}</h1>
        </Col>

        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
        <div className="personal-sections-wrapper">

        {user && (
          <>
            <div className="personal-section personal-activities">
              <h2 className="mb-4">{t('activities.following-activities')}</h2>
              {isLoadingFollowing ? (
                <div className="loader-container"><div className="loader"></div></div>
              ) : (
                <div className="topics-container">
                  {displayedFollowingActivities.length === 0 ? (
                    <div className="all-shows-info">
                      <img src={infoIcon} alt="Info" className="info-icon" />
                      {t('activities.empty-following-info')}
                    </div>
                  ) : (
                    <>
                      {displayedFollowingActivities.map((activity, index) => {
                        const isFemale = isFemaleFirstName(activity.user?.name);
                        return (
                          <Card className="latest-topic topic-card" key={index}>
                            <Card.Body>
                              <div className="user-activity-container">
                                <div className="activity-user">
                                <Link to={`/user/${activity.user.googleId}`} className="show-link">
                                  <img 
                                    src={activity.user.avatarUrl && activity.user.avatarUrl.includes('http') 
                                      ? activity.user.avatarUrl 
                                      : `${process.env.REACT_APP_API_BASE_URL}/img${activity.user.avatarUrl || '/default-avatar.png'}`} 
                                    alt="User Avatar" 
                                  />
                                  </Link>
                                </div>
                                <div className="single-activity-container">
                                  <div className="activity-date">{formatDate(activity.processedAt)}</div>
                                  <Link to={`/user/${activity.user.googleId}`} className="show-link">
                                  <span className="activity-username">{activity.user.name}&nbsp;</span>
                                  </Link>
                                  {activity.type === 'RATING_ENTRIES' ? (
                                    isFemale ? t('activities.has-rated-f') : t('activities.has-rated-m')
                                  ) : (
                                    isFemale ? t('activities.has-added-f') : t('activities.has-added-m')
                                  )}
                                  {activity.entries.map((entry, entryIndex) => (
                                    <div key={entryIndex} className="single-activity mt-2">
                                      <div className="activity-show-info">
                                        <div>
                                        {entry.show.isVerified ? (
                                          <Link to={`/shows/${entry.show.slug}`} className="show-link">
                                          <img 
                                            src={entry.show.poster.includes('http') 
                                              ? entry.show.poster 
                                              : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                                            alt={entry.show.title} 
                                            className="show-mini-poster" 
                                          />
                                          </Link>
                                            ) : (
                                              <img 
                                            src={entry.show.poster.includes('http') 
                                              ? entry.show.poster 
                                              : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                                            alt={entry.show.title} 
                                            className="show-mini-poster" 
                                          />
                                            )}
                                        </div>
                                        <div className="activity-content">
                                        {entry.show.isVerified ? (
                                          <Link to={`/shows/${entry.show.slug}`} className="show-link">
                                          <span className="show-title">{entry.show.title}</span>
                                          </Link>
                                        ) : (
                                          <span className="show-title">{entry.show.title}</span>
                                        )}
                                          
                                          <span className="show-info">
                                          {entry.show.theater && entry.show.location 
                                          ? `${entry.show.theater}, ${entry.show.location}` 
                                          : entry.show.theater || entry.show.location}
                                          </span>

                                          <span className="show-info">
                                          <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                                          {(!entry.dateWatched || entry.dateWatched === null) 
                                            ? t('common.no_date') 
                                            : new Date(entry.dateWatched).toLocaleDateString()
                                          }
                                          </span>
                                       
                                        </div>
                                      </div>
                                      {activity.type === 'RATING_ENTRIES' && entry.rating > 0 && (
                                        <div className="activity-rating">
                                          <div className="number-rating">{entry.rating}</div> {renderStarRating(entry.rating)}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                      {hasMoreFollowingActivities && (
                        <Button variant="light" onClick={loadMoreFollowingActivities} className="show-more-button mt-3">
                          {t('buttons.show_more')} ({followingActivities.length - displayedFollowingActivities.length})
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
         
          </>
        )}


          <div className="personal-section personal-activities">
            <h2 className="mb-4">{t('activities.all-activities')}</h2>
            {isLoadingRecent ? (
              <div className="loader-container"><div className="loader"></div></div>
            ) : (
              <div className="topics-container">
                {displayedActivities.map((activity, index) => {
                  const isFemale = isFemaleFirstName(activity.user?.name);
                  return (
                    <Card className="latest-topic topic-card" key={index}>
                      <Card.Body>
                        <div className="user-activity-container">
                          <div className="activity-user">
                          <Link to={`/user/${activity.user.googleId}`} className="show-link">
                            <img 
                              src={activity.user.avatarUrl && activity.user.avatarUrl.includes('http') 
                                ? activity.user.avatarUrl 
                                : `${process.env.REACT_APP_API_BASE_URL}/img${activity.user.avatarUrl || '/default-avatar.png'}`} 
                              alt="User Avatar" 
                            />
                            </Link>
                          </div>
                          <div className="single-activity-container">
                            <div className="activity-date">{formatDate(activity.processedAt)}</div>
                            <Link to={`/user/${activity.user.googleId}`} className="show-link">
                            <span className="activity-username">{activity.user.name}&nbsp;</span>
                            </Link>
                            {activity.type === 'RATING_ENTRIES' ? (
                              isFemale ? t('activities.has-rated-f') : t('activities.has-rated-m')
                            ) : (
                              isFemale ? t('activities.has-added-f') : t('activities.has-added-m')
                            )}
                            {activity.entries.map((entry, entryIndex) => (
                              <div key={entryIndex} className="single-activity mt-2">
                                <div className="activity-show-info">
                                  <div>
                                  {entry.show.isVerified ? (
                                    <Link to={`/shows/${entry.show.slug}`} className="show-link">
                                    <img 
                                      src={entry.show.poster.includes('http') 
                                        ? entry.show.poster 
                                        : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                                      alt={entry.show.title} 
                                      className="show-mini-poster" 
                                    />
                                    </Link>
                                      ) : (
                                        <img 
                                      src={entry.show.poster.includes('http') 
                                        ? entry.show.poster 
                                        : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                                      alt={entry.show.title} 
                                      className="show-mini-poster" 
                                    />
                                      )}
                                  </div>
                                  <div className="activity-content">
                                  {entry.show.isVerified ? (
                                    <Link to={`/shows/${entry.show.slug}`} className="show-link">
                                    <span className="show-title">{entry.show.title}</span>
                                    </Link>
                                  ) : (
                                    <span className="show-title">{entry.show.title}</span>
                                  )}
                                    
                                    <span className="show-info">
                                    {entry.show.theater && entry.show.location 
                                    ? `${entry.show.theater}, ${entry.show.location}` 
                                    : entry.show.theater || entry.show.location}
                                    </span>

                                    <span className="show-info">
                                    <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                                    {(!entry.dateWatched || entry.dateWatched === null) 
                                      ? t('common.no_date') 
                                      : new Date(entry.dateWatched).toLocaleDateString()
                                    }
                                    </span>
                                 
                                  </div>
                                </div>
                                {activity.type === 'RATING_ENTRIES' && entry.rating > 0 && (
                                  <div className="activity-rating">
                                    <div className="number-rating">{entry.rating}</div> {renderStarRating(entry.rating)}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })}

                {hasMoreActivities && (
                  <Button variant="light" onClick={loadMoreActivities} className="show-more-button mt-3">
                    {t('buttons.show_more')} ({recentActivities.length - displayedActivities.length})
                  </Button>
                )}
              </div>
            )}
          </div>
        

        
        </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ActivityFeed;
